import { computed, makeObservable, observable } from 'mobx';

import { RootStore } from 'mobx/stores';

import {
  CliniciansFetcher,
  IDoctorSignUpArgs,
  SearchedClinician
} from 'fetchers/CliniciansFetcher';

import Doctor from 'models/Doctor';

import { SPECIAL_FILTERS } from 'views/Filters/filters.constants';

import { DataMap } from './DataMap';

export default class CliniciansStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @observable
  defaultCliniciansForSelect = new DataMap<SearchedClinician>();

  @observable
  fetchedClinicians = new DataMap<SearchedClinician>();

  async fetchCliniciansByIds(ids: number[]) {
    if (!ids.length) {
      return Promise.resolve([]);
    }
    const fetchedClinicians = await CliniciansFetcher.fetchCliniciansByIds(ids);
    for (const clinician of fetchedClinicians) {
      this.fetchedClinicians.set(clinician.id, clinician);
    }
    return fetchedClinicians;
  }

  /**
   * Returns a list of clinicians that are cached or fetched from the server.
   * The order of the clinicians in the returned list is the same as the order of the ids.
   */
  async getCliniciansByIds(ids: number[]) {
    const validIds = ids.filter((id) => id !== SPECIAL_FILTERS.UNASSIGNED);

    if (!validIds.length) return [];

    const clinicians = new Array<SearchedClinician>(validIds.length);
    const missingIdsWithPositions = new Map<number, number>();

    // Get cached clinicians and track missing ones
    validIds.forEach((id, index) => {
      const cachedClinician = this.fetchedClinicians.get(id);
      if (cachedClinician) {
        clinicians[index] = cachedClinician;
      } else {
        missingIdsWithPositions.set(id, index);
      }
    });

    // Fetch missing clinicians if any
    if (missingIdsWithPositions.size) {
      const missingIds = Array.from(missingIdsWithPositions.keys());
      const fetchedClinicians = await this.fetchCliniciansByIds(missingIds);

      // Place fetched clinicians in their original positions
      fetchedClinicians.forEach((clinician) => {
        const position = missingIdsWithPositions.get(clinician.id)!;
        clinicians[position] = clinician;
      });
    }

    // Ensure all clinicians were found
    if (clinicians.filter(Boolean).length !== validIds.length) {
      throw new Error('Some clinicians were not found');
    }

    return clinicians;
  }

  async fetchAssigneesForFilterSelect() {
    const assigneesResponse = await CliniciansFetcher.searchClinicians();
    this.defaultCliniciansForSelect.setItems(assigneesResponse);
    for (const clinician of assigneesResponse) {
      this.fetchedClinicians.set(clinician.id, clinician);
    }
  }

  async deactivateClinician(clinicianId: number) {
    await CliniciansFetcher.deactivateClinician(clinicianId);
  }

  async activateClinician(clinicianId: number) {
    return await CliniciansFetcher.activateClinician(clinicianId);
  }

  async changePassword(clinicianId: number, oldPassword: string, newPassword: string) {
    await CliniciansFetcher.changePassword(clinicianId, oldPassword, newPassword);
  }

  async inviteClinician(data: IDoctorSignUpArgs): Promise<Doctor> {
    return await CliniciansFetcher.inviteClinician(data);
  }

  @computed
  get defaultCliniciansForSelectWithoutCurrentDoctor() {
    return this.defaultCliniciansForSelect.items.filter(
      (clinician) => clinician.id !== this.rootStore.stores.userStore.currentDoctor?.id
    );
  }
}
