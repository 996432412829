import { FC, Fragment, useEffect, useMemo, useRef, useState, MouseEvent } from 'react';

import { Box } from '@mui/material';
import { AnalyticEventAction } from 'analytics';

import { trackWqPaginationSectionUsageAnalyticsEvent } from 'analytics/events/section-usage';
import { trackTabNavigationAnalyticsEvent } from 'analytics/events/tab-navigation';
import debounce from 'debounce-promise';

import { isEmpty } from 'lodash/fp';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { useUnmount } from 'react-use';

import { workQueueTestSelectors } from 'tests/models/pages/work-queue/work-queue-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import { WorkQueueFetcher } from 'fetchers/WorkQueueFetcher';

import { getFiltersFromLocalStorageByKey } from 'utils/localStorage.utils';

import {
  extractItemTypesFilterQuery,
  extractResolvedDateFilterQuery,
  omitFalsyValuesFromQuery
} from 'utils/serverFiltersUtils';

import { TicketSortOrder } from 'models/Settings';

import DoctorDetails from 'views/Dashboard/DoctorDetails';

import {
  defaultResolvedDateFilterOption,
  WORK_QUEUE_RESOLVED_ITEMS_FILTERS_LOCAL_STORAGE_KEY
} from 'views/Filters/filters.constants';

import { ResolvedTicketsFiltersType } from 'views/Filters/filters.types';
import { useSetPersistFilters } from 'views/Filters/useFilters';

import { WorkQueueResolvedItemsFilters } from 'views/Filters/WorkQueueResolvedItemsFilters';
import { DEBOUNCE_DELAY } from 'views/Pages/CareManagement/CareManagement.hooks';
import {
  workQueueTabToNameMap,
  workQueueToggleButtonOptions
} from 'views/WorkQueue/WorkQueue.constants';
import {
  ResolvedItemsSection,
  WorkQueueItemType,
  WorkQueueSectionName,
  WorkQueueTab,
  WorkQueueTabName
} from 'views/WorkQueue/WorkQueue.types';

import { buildServerSectionQuery } from 'views/WorkQueue/WorkQueue.utils';
import { WorkQueueViewLayout } from 'views/WorkQueue/WorkQueueViewLayout';

import { WqTicketPlaceholder } from 'views/WorkQueue/WqTicketPlaceholder';

import RefreshButton from 'components/Buttons/RefreshDataButton';

import FixedLoader from 'components/Loaders/FixedLoader';
import { Paginator, PaginatorAction } from 'components/Paginator';
import { CardRow } from 'components/Surfaces/Card';
import CallbackRequestTicketRow from 'components/Ticket/TicketRow/CallbackRequestTicketRow';
import OperatorTicketRow from 'components/Ticket/TicketRow/OperatorTicketRow';
import SymptomOperatorTicketRow from 'components/Ticket/TicketRow/SymptomOperatorTicketRow';
import TaskTicketRow from 'components/Ticket/TicketRow/TaskTicketRow';
import TicketReportRow from 'components/Ticket/TicketRow/TicketReportRow';
import TicketOverviewProvider from 'components/Ticket/TicketsContainers/TicketOverviewProvider';
import { Section } from 'components/Ticket/TicketsContainers/TicketsContainers.constants';
import { NoTickets } from 'components/Ticket/TicketsContainers/TicketsOverview';
import { ExclusiveToggleButton } from 'components/UIkit/atoms/Button';

export const WorkQueueResolvedItemsView: FC = observer(() => {
  const localStorageFilters = getFiltersFromLocalStorageByKey(
    WORK_QUEUE_RESOLVED_ITEMS_FILTERS_LOCAL_STORAGE_KEY
  );
  const defaultFilters: ResolvedTicketsFiltersType = localStorageFilters || {
    nameOrMrn: '',
    resolvedDate: defaultResolvedDateFilterOption,
    resolvedBy: null,
    ticketType: null,
    patientTags: null,
    providers: null,
    locations: null,
    episodeIds: null
  };

  const [filters, setFilters] = useState<ResolvedTicketsFiltersType>(defaultFilters);
  const [sectionData, setSectionData] = useState<ResolvedItemsSection>({
    items: [],
    totalPages: 0,
    totalItems: 0,
    pageNumber: 0
  });
  const [currentPage, setCurrentPage] = useState(sectionData.pageNumber + 1);
  const [debouncedPage, setDebouncedPage] = useState(sectionData.pageNumber + 1);
  const [isLoading, setIsLoading] = useState(false);
  const isInitialPageLoaded = useRef(false);
  const { settingsStore, workQueueStore, ticketTypesStore, userStore } = useStores();
  const history = useHistory();

  const handleFilterChanged = (newFilters: ResolvedTicketsFiltersType) => {
    setFilters({
      ...filters,
      ...newFilters
    });
    setDebouncedPage(1);
    setCurrentPage(1);
  };

  const { updateFiltersByKey } = useSetPersistFilters<ResolvedTicketsFiltersType>(
    filters,
    handleFilterChanged,
    {
      localStorageKey: WORK_QUEUE_RESOLVED_ITEMS_FILTERS_LOCAL_STORAGE_KEY,
      excludeFieldKeys: ['nameOrMrn']
    }
  );

  const debouncedSetPage = useMemo(
    () =>
      debounce((currentPage: number) => {
        setDebouncedPage(currentPage);
      }, DEBOUNCE_DELAY),
    []
  );

  useEffect(
    function fetchWorkQueue() {
      const handleFetchWorkQueue = async () => {
        setIsLoading(true);

        const resolvedAtDates = extractResolvedDateFilterQuery(filters.resolvedDate?.value);

        const { sections, patients } = await WorkQueueFetcher.fetchWorkQueue({
          sections: [
            buildServerSectionQuery({
              sectionName: WorkQueueSectionName.ResolvedItems,
              pageNumber: debouncedPage - 1,
              recordsPerPage: settingsStore.institutionSettings.wqRecordsPerPage,
              ticketSortOrder: TicketSortOrder.NewestFirst
            })
          ],
          filters: omitFalsyValuesFromQuery({
            nameOrMrn: filters.nameOrMrn,
            ...(!isEmpty(resolvedAtDates) && { resolvedAtDates }),
            resolvedByIds: filters.resolvedBy?.map((option) => option.value as number),
            patientTags: filters.patientTags?.map((option) => option.value as number),
            providers: filters.providers?.map((option) => option.value?.id as string),
            locations: filters.locations?.map((option) => option.value as number),
            episodes: filters.episodeIds?.map((option) => option.value as number),
            ...extractItemTypesFilterQuery(ticketTypesStore, filters.ticketType!)
          })
        });
        workQueueStore.updatePatientsMap(patients);
        setSectionData(sections[WorkQueueSectionName.ResolvedItems]!);

        setIsLoading(false);

        const isTopPaginatorVisible = window.scrollY < 315; //315 = the height of all the content above the tickets

        if (!isTopPaginatorVisible) {
          window.scrollTo(0, 0);
        }

        if (!isInitialPageLoaded.current) {
          isInitialPageLoaded.current = true;
        }
      };

      handleFetchWorkQueue();
    },
    [settingsStore, workQueueStore, ticketTypesStore, filters, debouncedPage]
  );

  useUnmount(function reset() {
    workQueueStore.patientsMap.clear();
  });

  const handlePageChanged = (
    currentPage: number,
    action: PaginatorAction,
    previousPage: number
  ) => {
    trackWqPaginationSectionUsageAnalyticsEvent(Section.ResolvedItems, action, previousPage);
    setCurrentPage(currentPage);
    debouncedSetPage(currentPage);
  };

  const handleTabClicked = (_event: MouseEvent<HTMLElement>, newSelection: string | number) => {
    trackTabNavigationAnalyticsEvent({
      action: AnalyticEventAction.TabSelect,
      value: workQueueTabToNameMap[newSelection as WorkQueueTab],
      tab: workQueueTabToNameMap[WorkQueueTab.ResolvedItems]
    });

    if (newSelection === WorkQueueTab.ResolvedItems) {
      return;
    }

    if (newSelection === WorkQueueTab.OpenItems) {
      history.push('/work-queue/open-items');
    }
  };

  const hasTickets = sectionData.totalItems > 0 && sectionData.items.length > 0;
  const isEmptyState = isInitialPageLoaded.current && !hasTickets;

  return (
    <WorkQueueViewLayout
      filtersComponent={
        <WorkQueueResolvedItemsFilters filters={filters!} updateFiltersByKey={updateFiltersByKey} />
      }
      testHook={workQueueTestSelectors.resolvedItemsTab.container}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={40}>
        <DoctorDetails doctor={userStore.currentDoctor} showDepartments={false} />

        <Box display="flex" alignItems="center" gap={20}>
          <ExclusiveToggleButton
            options={workQueueToggleButtonOptions}
            value={WorkQueueTab.ResolvedItems}
            onChange={handleTabClicked}
            variant="secondary"
          />

          <RefreshButton
            onClick={() => {}}
            isLoading={false}
            lastUpdatedTime={null}
            testHook={workQueueTestSelectors.refreshDataButton}
            isDisabled
            width="206px"
            hasBoxShadow={false}
            alignStart
          />
        </Box>
      </Box>

      {isEmptyState && !isLoading && <NoTickets />}

      {isInitialPageLoaded.current && (
        <>
          {isEmptyState && isLoading && (
            <Box mt={40}>
              <WqTicketPlaceholder />
              <WqTicketPlaceholder />
              <WqTicketPlaceholder />
              <WqTicketPlaceholder />
              <WqTicketPlaceholder />
              <WqTicketPlaceholder />
              <WqTicketPlaceholder />
              <WqTicketPlaceholder />
            </Box>
          )}

          {!isEmptyState && (
            <>
              {sectionData.totalPages > 1 && (
                <Paginator
                  currentPage={currentPage}
                  totalPages={sectionData.totalPages}
                  onPageChange={handlePageChanged}
                  testHookPrefix={`${WorkQueueSectionName.ResolvedItems}-paginator-top`}
                />
              )}

              <TicketOverviewProvider
                actionCallbacks={{}}
                useIntersectionObserver
                tab={WorkQueueTabName.ResolvedItems}
                hideActionButtons
              >
                <div className="tickets-list">
                  {sectionData.items.map(({ itemType, itemData }, index) => {
                    const patient = workQueueStore.getPatientModel(itemData.patientId);

                    return (
                      <Fragment key={itemData.id}>
                        {isLoading ? (
                          <WqTicketPlaceholder />
                        ) : (
                          <>
                            {itemType === WorkQueueItemType.OperatorTicket &&
                              !itemData.isSymptomOperatorTicket && (
                                <OperatorTicketRow
                                  ticket={itemData}
                                  withPatientLink
                                  ticketIndex={index}
                                  ticketSectionCurrentPage={currentPage - 1}
                                />
                              )}

                            {itemType === WorkQueueItemType.OperatorTicket &&
                              itemData.isSymptomOperatorTicket && (
                                <SymptomOperatorTicketRow
                                  ticket={itemData}
                                  withPatientLink
                                  ticketIndex={index}
                                  ticketSectionCurrentPage={currentPage - 1}
                                />
                              )}

                            {itemType === WorkQueueItemType.PatientTicket && (
                              <TicketReportRow
                                ticket={itemData}
                                reports={patient!.getAcknowledgedReportsByTicketIdSortedBySeverity(
                                  itemData.id
                                )}
                                withPatientLink
                                ticketIndex={index}
                                ticketSectionCurrentPage={currentPage - 1}
                              />
                            )}

                            {itemType === WorkQueueItemType.TaskTicket && (
                              <CardRow>
                                <TaskTicketRow
                                  ticket={itemData}
                                  withPatientLink
                                  ticketIndex={index}
                                  ticketSectionCurrentPage={currentPage - 1}
                                />
                              </CardRow>
                            )}

                            {itemType === WorkQueueItemType.CallbackRequestTicket && (
                              <CallbackRequestTicketRow
                                ticket={itemData}
                                withPatientLink
                                ticketIndex={index}
                                ticketSectionCurrentPage={currentPage - 1}
                              />
                            )}
                          </>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </TicketOverviewProvider>

              {sectionData.totalPages > 1 && (
                <Paginator
                  currentPage={currentPage}
                  totalPages={sectionData.totalPages}
                  onPageChange={handlePageChanged}
                  testHookPrefix={`${WorkQueueSectionName.ResolvedItems}-paginator-bottom`}
                />
              )}
            </>
          )}
        </>
      )}

      {!isInitialPageLoaded.current && <FixedLoader />}
    </WorkQueueViewLayout>
  );
});
