// @ts-strict-ignore
import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';

import { RootStore } from 'mobx/stores';

import { isToday } from 'utils/DateUtils';
import {
  filterByNameOrMrn,
  filterLocations,
  filterOverdue,
  filterOverdueOral,
  filterTags,
  getTicketTypesFiltersKeys,
  isPatientInProviders
} from 'utils/filtersUtils';

import { getDefaultFilters } from 'utils/serverFiltersUtils';

import { AllTicketsCluster, TicketsCluster } from 'utils/TicketClusteringUtils';

import { TicketSubTypeOption } from 'utils/TicketType.utils';

import { getPatientIdFromUrl } from 'utils/urlUtils';

import {
  TICKET_TYPE_CALLBACK_ID,
  TICKET_TYPE_EPISODES_ID,
  TICKET_TYPE_NON_EPISODES_ID,
  TICKET_TYPE_ORAL_ONCO_OVERDUE_ID,
  TICKET_TYPE_ORAL_ONCO_REPORT_ID,
  TICKET_TYPE_OVERDUE_ID,
  TICKET_TYPE_SYMPTOM_ASSESSMENT_REPORT_ID
} from 'constants/itemTypes.const';

import Patient from 'models/Patient';
import { ReportType } from 'models/QuestionnaireAnswer';
import { TicketTypeKind } from 'models/TicketTypes';

import { TASK_QUERY_FROM_DATE_KEY, TASK_QUERY_TO_DATE_KEY } from 'views/Filters/filters.constants';
import {
  DefaultTasksRequestParams,
  SearchFiltersType,
  TasksQueryRequestParams,
  TicketsQueryRequestParams
} from 'views/Filters/filters.types';

import { getNodeKey } from 'views/Pages/PracticeManagement/TicketTypes/TicketTypeTree';
import { ItemCategory } from 'views/WorkQueue/WorkQueue.types';

export class TicketFiltersStore {
  rootStore: RootStore;

  @observable
  filters: SearchFiltersType;

  @observable
  defaultTasksQueryValues: TasksQueryRequestParams;

  @observable
  defaultTicketsQueryValues: TicketsQueryRequestParams;

  @observable
  dataReady: boolean = false;

  @observable
  updatingFilters: boolean = false;

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.filters = getDefaultFilters();
  }

  @action
  setUpdatingFilters(isUpdating: boolean) {
    this.updatingFilters = isUpdating;
  }

  @action
  updateFilters = (newFilters: SearchFiltersType) => {
    this.filters = {
      ...this.filters,
      ...newFilters
    };
  };

  @action
  setDefaultTaskQuery = (defaultQueryValue: DefaultTasksRequestParams) => {
    this.defaultTasksQueryValues = defaultQueryValue;
  };

  @action
  resetDefaultTaskQuery = () => {
    this.defaultTasksQueryValues = {};
  };

  @action
  setDefaultTicketQuery = (defaultQueryValue: TicketsQueryRequestParams) => {
    this.defaultTicketsQueryValues = defaultQueryValue;
  };

  @action
  resetFilters = () => {
    this.filters = getDefaultFilters();
  };

  @action
  resetFiltersAndDefaultQueryValues = () => {
    this.resetFilters();
    this.resetDefaultTaskQuery();
    this.defaultTicketsQueryValues = {};

    const patientId = getPatientIdFromUrl();
    if (patientId) {
      this.defaultTasksQueryValues.patientId = patientId;
      this.defaultTicketsQueryValues.patientId = patientId;
    }
  };

  @action
  setEpisodeTasksFilter = () => {
    this.filters.episodeTasks = this.episodeTasksFilterValue;
  };

  @action
  setQueryFromDate(date: Date | null) {
    localStorage.setItem(TASK_QUERY_FROM_DATE_KEY, JSON.stringify(date));
    this.filters.fromDate = date;
    const isAfterToDate =
      this.rootStore.stores.ticketFiltersStore.filters.toDate &&
      moment(date).isAfter(this.rootStore.stores.ticketFiltersStore.filters.toDate);
    isAfterToDate && this.setQueryToDate(date);
  }

  @action
  setQueryToDate(date: Date | null) {
    const isNewDateToday = isToday(date);
    localStorage.setItem(TASK_QUERY_TO_DATE_KEY, isNewDateToday ? null : JSON.stringify(date));
    this.filters.toDate = date;
    const isBeforeToDate = this.filters.fromDate && moment(date).isBefore(this.filters.fromDate);
    if (isBeforeToDate) {
      this.setQueryFromDate(isNewDateToday ? null : date);
    }
  }

  // filters applied only on awaiting callback and overdue symptom reports (meaning patients not tickets)
  handlePatientFilter = (patient: Patient) => {
    const { filters } = this;
    if (!patient) {
      return false;
    }

    if (filters.searchTerm && !filterByNameOrMrn(patient, filters.searchTerm)) {
      return false;
    }

    if (
      filters.patientTags?.length &&
      !filterTags(
        patient,
        filters.patientTags.map((tag) => tag.value)
      )
    ) {
      return false;
    }

    if (
      filters.providers?.length &&
      !isPatientInProviders(
        patient,
        this.filters.providers.map((option) => option.value.id)
      )
    ) {
      return false;
    }

    if (
      filters.locations?.length &&
      !filterLocations(
        patient,
        filters.locations.map((location) => location.value)
      )
    ) {
      return false;
    }

    if (!filterOverdue(patient, this.ticketTypesFiltersKeys)) {
      return false;
    }

    if (!filterOverdueOral(patient, this.ticketTypesFiltersKeys)) {
      return false;
    }

    return true;
  };

  applyFiltersOnPatients = (patients: Patient[]) => {
    return patients.filter((patient) => this.handlePatientFilter(patient));
  };

  @computed
  get reportTypesFilterValue() {
    const reportTypes: ReportType[] = [];

    if (this.isOralReportsFilterSelected) {
      reportTypes.push(ReportType.Oral);
    }

    if (this.isSymptomAssessmentFilterSelected) {
      reportTypes.push(ReportType.Distress);
    }

    if (this.isCallbackRequestsFilterSelected) {
      reportTypes.push(ReportType.CallbackRequestTickets);
    }

    return reportTypes;
  }

  @computed
  get itemCategoriesFilterValue() {
    const itemCategories: ItemCategory[] = [];

    if (this.ticketTypesFiltersKeys.has(TICKET_TYPE_EPISODES_ID)) {
      itemCategories.push(ItemCategory.EpisodeTasks);
    }

    if (this.ticketTypesFiltersKeys.has(TICKET_TYPE_NON_EPISODES_ID)) {
      itemCategories.push(ItemCategory.NonEpisodeTasks);
    }

    if (this.ticketTypesFiltersKeys.has(TICKET_TYPE_OVERDUE_ID)) {
      itemCategories.push(ItemCategory.OverdueSymptomReports);
    }

    if (this.ticketTypesFiltersKeys.has(TICKET_TYPE_ORAL_ONCO_OVERDUE_ID)) {
      itemCategories.push(ItemCategory.OverdueOralReports);
    }

    return itemCategories;
  }

  @computed
  get episodeTasksFilterValue() {
    const { isEpisodeTasksFilterSelected, isNonEpisodeFilterSelected } = this;
    const bothSelected = isEpisodeTasksFilterSelected && isNonEpisodeFilterSelected;
    const noFiltersSelected = !isEpisodeTasksFilterSelected && !isNonEpisodeFilterSelected;
    if (noFiltersSelected || bothSelected) {
      return null;
    }

    return isEpisodeTasksFilterSelected && !isNonEpisodeFilterSelected;
  }

  @computed
  // filter key is either categoryId or itemId_catgoryId (item can be type or subtype)
  get ticketTypesFiltersKeys(): Set<string> {
    return getTicketTypesFiltersKeys(
      this.rootStore.stores.ticketTypesStore,
      this.filters.ticketType
    );
  }

  @computed
  get someSymptomFilters() {
    return this.filters.ticketType?.some((filter: TicketSubTypeOption) => {
      const filterId = filter.value;
      const ticketTypeKey = getNodeKey(parseInt(filterId), filter.parentId);
      const typeNode = this.rootStore.stores.ticketTypesStore.getTicketType(ticketTypeKey);
      return Boolean(typeNode) && typeNode.kind === TicketTypeKind.symptom;
    });
  }

  @computed
  get someOperatorFilters() {
    return this.filters.ticketType?.some((filter: TicketSubTypeOption) => {
      const filterId = filter.value;
      const ticketTypeKey = getNodeKey(parseInt(filterId), filter.parentId);
      const typeNode = this.rootStore.stores.ticketTypesStore.getTicketType(ticketTypeKey);
      return Boolean(typeNode) && typeNode.kind === TicketTypeKind.other;
    });
  }

  @computed
  get hasAnyTicketTypeFilters() {
    return this.ticketTypesFiltersKeys.size > 0;
  }

  @computed
  get hasOperatorFilters() {
    return this.hasAnyTicketTypeFilters && this.someOperatorFilters;
  }

  @computed
  get isSymptomAssessmentFilterSelected() {
    return this.ticketTypesFiltersKeys.has(TICKET_TYPE_SYMPTOM_ASSESSMENT_REPORT_ID);
  }

  @computed
  get isOralReportsFilterSelected() {
    return this.ticketTypesFiltersKeys.has(TICKET_TYPE_ORAL_ONCO_REPORT_ID);
  }

  @computed
  get isCallbackRequestsFilterSelected() {
    return this.ticketTypesFiltersKeys.has(TICKET_TYPE_CALLBACK_ID);
  }

  @computed
  get showTasks() {
    return !this.hasAnyTicketTypeFilters || this.hasTaskTicketTypeFilter;
  }

  @computed
  get hasTaskTicketTypeFilter() {
    return (
      this.ticketTypesFiltersKeys.has(TICKET_TYPE_EPISODES_ID) || this.isNonEpisodeFilterSelected
    );
  }

  @computed
  get hasCallbackRequestFilter() {
    return this.ticketTypesFiltersKeys.has(TICKET_TYPE_CALLBACK_ID);
  }

  @computed
  get isNonEpisodeFilterSelected(): boolean {
    return this.ticketTypesFiltersKeys.has(TICKET_TYPE_NON_EPISODES_ID);
  }

  @computed
  get isEpisodeTasksFilterSelected(): boolean {
    return this.ticketTypesFiltersKeys.has(TICKET_TYPE_EPISODES_ID);
  }

  applyFiltersOnCluster = (cluster: AllTicketsCluster) => {
    return {
      ticketsCluster: this.applyFiltersOnTicketsCluster(cluster.ticketsCluster),
      overduePatients: this.showOverdue ? this.applyFiltersOnPatients(cluster.overduePatients) : []
    };
  };

  @computed
  get showOverdue() {
    return (
      !this.hasAnyTicketTypeFilters ||
      this.ticketTypesFiltersKeys.has(TICKET_TYPE_OVERDUE_ID) ||
      this.ticketTypesFiltersKeys.has(TICKET_TYPE_ORAL_ONCO_OVERDUE_ID)
    );
  }

  // TODO: remove this when we handle
  //  cluster for single patient page on the server
  applyFiltersOnTicketsCluster = (clusteredTickets: TicketsCluster) => {
    return {
      assignedTickets: clusteredTickets.assignedTickets,
      operatorTickets:
        this.hasOperatorFilters || !this.hasAnyTicketTypeFilters
          ? clusteredTickets.operatorTickets
          : [],
      otherSymptomTickets: clusteredTickets.otherSymptomTickets,
      urgentSymptomsTickets: clusteredTickets.urgentSymptomsTickets,
      tasks: clusteredTickets.tasks,
      callbackRequestTickets:
        (this.hasAnyTicketTypeFilters || this.hasTaskTicketTypeFilter) &&
        !this.hasCallbackRequestFilter
          ? []
          : clusteredTickets.callbackRequestTickets
    };
  };

  @action
  replaceFilters(filterSetFilters: SearchFiltersType) {
    this.filters = { ...getDefaultFilters(), ...filterSetFilters };
  }
}

export default TicketFiltersStore;
