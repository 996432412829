import { isEmpty, isNil, isString, isUndefined, mergeWith } from 'lodash/fp';

import moment from 'moment/moment';

import { rootStore, SettingsStore, TicketTypesStore } from 'mobx/stores';

import { formatDate, LONG_DATE_FORMAT, startOfToday } from 'utils/DateUtils';
import { getTicketTypesFiltersKeys } from 'utils/filtersUtils';
import { TicketSubTypeOption } from 'utils/TicketType.utils';

import {
  TICKET_TYPE_CALLBACK_ID,
  TICKET_TYPE_EPISODES_ID,
  TICKET_TYPE_NON_EPISODES_ID,
  TICKET_TYPE_ORAL_ONCO_OVERDUE_ID,
  TICKET_TYPE_ORAL_ONCO_REPORT_ID,
  TICKET_TYPE_OVERDUE_ID,
  TICKET_TYPE_SYMPTOM_ASSESSMENT_REPORT_ID
} from 'constants/itemTypes.const';

import { ReportType } from 'models/QuestionnaireAnswer';

import {
  RESOLVED_DATE_FILTER_ALL_TIME_OPTION_ID,
  RESOLVED_DATE_FILTER_LAST_WEEK_OPTION_ID,
  RESOLVED_DATE_FILTER_THIS_WEEK_OPTION_ID,
  RESOLVED_DATE_FILTER_TODAY_OPTION_ID,
  RESOLVED_DATE_FILTER_YESTERDAY_OPTION_ID,
  ResolvedDateFilterOptionId,
  TASK_QUERY_FROM_DATE_KEY,
  TASK_QUERY_TO_DATE_KEY
} from 'views/Filters/filters.constants';
import {
  SearchFiltersType,
  SearchRequestParams,
  TasksQueryRequestParams,
  TicketsQueryRequestParams,
  TicketTypeRequestParam
} from 'views/Filters/filters.types';

import { TicketTypeNode } from 'views/Pages/PracticeManagement/TicketTypes/TicketTypeNode';
import { getNodeKey } from 'views/Pages/PracticeManagement/TicketTypes/TicketTypeTree';
import { ItemCategory, WorkQueueRequestFilters } from 'views/WorkQueue/WorkQueue.types';

import {
  itemCategoriesMap,
  getItemCategoriesOptions
} from 'components/Ticket/TicketForms/TicketsInfoFormFragments/OperatorTicketsInfoFormCommon';

export const convertToSafeFilterValue = (filterValue: any) => {
  const isStringFilter = isString(filterValue);
  filterValue = isStringFilter ? filterValue.trim() : filterValue;

  if (Array.isArray(filterValue) && filterValue.length === 0) {
    return null;
  }

  if (isStringFilter && filterValue.length === 0) {
    return null;
  }

  if (isUndefined(filterValue)) {
    return null;
  }

  return filterValue;
};

export const sanitizeFiltersOrQuery = (unSanitizedObject: Record<string, any>) => {
  let sanitizedQuery: Record<string, any> = {};
  Object.keys(unSanitizedObject).forEach((key) => {
    sanitizedQuery[key] = convertToSafeFilterValue(unSanitizedObject[key]);
  });

  return sanitizedQuery;
};

const isSafeFilterValue = (filterValue: any) => {
  const isStringFilter = isString(filterValue);
  filterValue = isStringFilter ? filterValue.trim() : filterValue;

  if (isEmpty(filterValue)) {
    return false;
  }

  return !isNil(filterValue);
};

export const omitFalsyValuesFromQuery = (rawQuery: Record<string, any>) => {
  let query: Record<string, any> = {};
  Object.keys(rawQuery).forEach((key) => {
    if (isSafeFilterValue(rawQuery[key])) {
      if (isString(rawQuery[key])) {
        query[key] = rawQuery[key].trim();
      } else {
        query[key] = rawQuery[key];
      }
    }
  });

  return query;
};

export const extractTicketTypeQueryFromFilters = (
  ticketTypeFilter?: TicketSubTypeOption[] | null
) => {
  if (!ticketTypeFilter) {
    return null;
  }
  const { ticketTypesStore } = rootStore.stores;
  const ticketTypesMap: Record<number, TicketTypeRequestParam> = {};
  const ticketTypes: TicketTypeRequestParam[] = [];
  ticketTypeFilter.forEach((type) => {
    const nodeKey = getNodeKey(Number(type.value), type.parentId);
    const typeNode = ticketTypesStore.getTicketType(nodeKey);

    // there are edge cases with hard coded filters (overdue, awaiting callback etc...)
    if (!typeNode) {
      return;
    }

    const categoryId = typeNode.category?.id;
    const isCategory = !typeNode.parent;
    const categoryEntry = (ticketTypesMap[categoryId] = ticketTypesMap[categoryId] ?? {
      ticketTypeId: categoryId,
      ticketSubTypesIds: [],
      isCategoryFilterChosen: isCategory
    });

    const newEntries = isCategory ? [] : [typeNode.id];
    categoryEntry.ticketSubTypesIds.push(...newEntries);
    categoryEntry.isCategoryFilterChosen = categoryEntry.isCategoryFilterChosen || isCategory;
  });

  Object.keys(ticketTypesMap).forEach((category) => {
    const { ticketTypeId, ticketSubTypesIds, isCategoryFilterChosen } =
      ticketTypesMap[Number(category)];
    ticketTypes.push({
      ticketTypeId,
      ticketSubTypesIds: isCategoryFilterChosen ? [] : ticketSubTypesIds
    });
  });

  return ticketTypes;
};

export function extractCommonQueryFromFilters(filters: SearchFiltersType) {
  return {
    assignees: filters.assignees?.map((option) => option.value),
    providers: filters.providers?.map((option) => option.value.id),
    locations: filters.locations?.map((option) => option.value),
    nameOrMrn: filters.searchTerm,
    patientTags: filters.patientTags?.map((option) => option.value)
  };
}

export const extractTicketQueryFromFilters = (
  filters: SearchFiltersType
): TicketsQueryRequestParams => {
  const ticketTypes = extractTicketTypeQueryFromFilters(filters?.ticketType);

  return {
    ...extractCommonQueryFromFilters(filters),
    ticketTypes
  };
};

export const extractTaskQueryFromFilters = (
  filters: SearchFiltersType
): TasksQueryRequestParams => {
  const { fromDate, toDate } = filters;
  return {
    ...extractCommonQueryFromFilters(filters),
    episodes: filters.episodeIds?.map((option) => option.value),
    roles: filters.role?.map((option) => option.value),
    owners: filters.owners?.map((option) => option.value),
    taskStatuses: filters.status?.map((option) => option.value),
    episodeNumbers: filters.episodeNumbers?.map((option) => option.value),
    searchTerm: filters.taskSearchTerm,
    lastNameFirstLetter: filters.lastNameFirstLetter?.map((option) => option.value),
    episodeTasks: filters.episodeTasks,
    fromDate: fromDate ? formatDate(fromDate, LONG_DATE_FORMAT) : null,
    toDate: toDate ? formatDate(toDate, LONG_DATE_FORMAT) : null
  };
};

export const mergeWithQueryDefaults = (
  query: SearchRequestParams,
  queryDefaults: SearchRequestParams
) =>
  mergeWith(
    (objectValue, srcValue) => (isUndefined(objectValue) ? srcValue : objectValue),
    query,
    queryDefaults
  );

export const getDefaultDateFilters = () => {
  const fromDate = JSON.parse(localStorage.getItem(TASK_QUERY_FROM_DATE_KEY));
  const toDate = JSON.parse(localStorage.getItem(TASK_QUERY_TO_DATE_KEY));
  return {
    fromDate: fromDate ? new Date(fromDate) : null,
    toDate: toDate ? new Date(toDate) : startOfToday()
  };
};

export const getDefaultDatesRequestParams = () => {
  const { fromDate, toDate } = getDefaultDateFilters();

  return {
    fromDate: fromDate ? formatDate(fromDate, LONG_DATE_FORMAT) : null,
    toDate: formatDate(toDate, LONG_DATE_FORMAT)
  };
};

export const getDefaultFilters = (): SearchFiltersType => ({
  searchTerm: null,
  taskSearchTerm: null,
  lastNameFirstLetter: null,
  providers: null,
  assignees: null,
  locations: null,
  patientTags: null,
  ticketType: null,
  role: null,
  episodeIds: null,
  episodeNumbers: null,
  owners: null,
  status: null,
  ...getDefaultDateFilters()
});

export const extractItemTypesFilterQuery = (
  ticketTypesStore: TicketTypesStore,
  ticketTypeFilter: TicketSubTypeOption[] | null
) => {
  const ticketTypesFiltersKeys = getTicketTypesFiltersKeys(ticketTypesStore, ticketTypeFilter);

  const reportTypes: ReportType[] = [];
  const itemCategories: ItemCategory[] = [];

  if (ticketTypesFiltersKeys.has(TICKET_TYPE_ORAL_ONCO_REPORT_ID)) {
    reportTypes.push(ReportType.Oral);
  }

  if (ticketTypesFiltersKeys.has(TICKET_TYPE_SYMPTOM_ASSESSMENT_REPORT_ID)) {
    reportTypes.push(ReportType.Distress);
  }

  if (ticketTypesFiltersKeys.has(TICKET_TYPE_CALLBACK_ID)) {
    reportTypes.push(ReportType.CallbackRequestTickets);
  }

  if (ticketTypesFiltersKeys.has(TICKET_TYPE_EPISODES_ID)) {
    itemCategories.push(ItemCategory.EpisodeTasks);
  }

  if (ticketTypesFiltersKeys.has(TICKET_TYPE_NON_EPISODES_ID)) {
    itemCategories.push(ItemCategory.NonEpisodeTasks);
  }

  if (ticketTypesFiltersKeys.has(TICKET_TYPE_OVERDUE_ID)) {
    itemCategories.push(ItemCategory.OverdueSymptomReports);
  }

  if (ticketTypesFiltersKeys.has(TICKET_TYPE_ORAL_ONCO_OVERDUE_ID)) {
    itemCategories.push(ItemCategory.OverdueOralReports);
  }

  const ticketTypes = extractTicketTypeQueryFromFilters(ticketTypeFilter);

  const query: WorkQueueRequestFilters = {
    // derived query part from item types filter
    // (hard coded ids, not real ticket types)
    ...(reportTypes.length > 0 && {
      reportTypes
    }),
    ...(itemCategories.length > 0 && {
      itemCategories
    }),
    ...(ticketTypes && { ticketTypes })
  };

  return query;
};

export const extractResolvedDateFilterQuery = (
  resolvedDateValue?: ResolvedDateFilterOptionId | null
): {} | { fromDate: string; toDate: string } => {
  if (!resolvedDateValue) {
    return {};
  }

  switch (resolvedDateValue) {
    case RESOLVED_DATE_FILTER_TODAY_OPTION_ID:
      return {
        fromDate: moment().format(LONG_DATE_FORMAT),
        toDate: moment().format(LONG_DATE_FORMAT)
      };

    case RESOLVED_DATE_FILTER_YESTERDAY_OPTION_ID:
      return {
        fromDate: moment().subtract(1, 'days').format(LONG_DATE_FORMAT), // Yesterday
        toDate: moment().subtract(1, 'days').format(LONG_DATE_FORMAT) // Yesterday
      };

    case RESOLVED_DATE_FILTER_THIS_WEEK_OPTION_ID:
      return {
        fromDate: moment().startOf('isoWeek').format(LONG_DATE_FORMAT), // Monday of this week
        toDate: moment().endOf('isoWeek').format(LONG_DATE_FORMAT) // Sunday of this week
      };

    case RESOLVED_DATE_FILTER_LAST_WEEK_OPTION_ID:
      return {
        fromDate: moment().subtract(1, 'weeks').startOf('isoWeek').format(LONG_DATE_FORMAT), // Monday of last week
        toDate: moment().subtract(1, 'weeks').endOf('isoWeek').format(LONG_DATE_FORMAT) // Sunday of last week
      };

    case RESOLVED_DATE_FILTER_ALL_TIME_OPTION_ID:
      return {};
  }
};

/**
 * Recursively search for a TicketTypeNode with given id in a category tree.
 */
export const searchInCategorySubTree = (
  root: TicketTypeNode,
  id: number
): TicketTypeNode | undefined => {
  if (root.id === id) {
    return root;
  }

  for (const child of root.children) {
    const found = searchInCategorySubTree(child, id);
    if (found) return found;
  }

  return undefined;
};

export const convertTicketTypeQueryToFilters = (
  ticketTypes: TicketTypeRequestParam[]
): TicketSubTypeOption[] => {
  const { ticketTypesStore } = rootStore.stores;
  const filterOptions: TicketSubTypeOption[] = [];
  try {
    ticketTypes.forEach(({ ticketTypeId, ticketSubTypesIds }) => {
      const isCategoryFilterChosen =
        Array.isArray(ticketSubTypesIds) && ticketSubTypesIds.length === 0;
      const categoryNode = ticketTypesStore.getTicketType(String(ticketTypeId));
      if (isCategoryFilterChosen) {
        if (categoryNode) {
          filterOptions.push({
            label: `All: ${categoryNode.name}`,
            value: categoryNode.id.toString(),
            parentId: null,
            parentName: null
          });
        }
      } else if (ticketSubTypesIds) {
        ticketSubTypesIds.forEach((subTypeId) => {
          const typeNode = searchInCategorySubTree(categoryNode, subTypeId);
          if (typeNode) {
            filterOptions.push({
              value: String(subTypeId),
              label:
                typeNode.parent?.id === categoryNode?.id
                  ? typeNode.name
                  : `${typeNode.parent?.name} - ${typeNode.name}`,
              parentId: typeNode.parent?.id || null,
              parentName: typeNode.parent?.name || null
            });
          }
        });
      }
    });
  } catch (error) {
    console.error('Error converting ticket type query to filters', error);
  }

  return filterOptions;
};

// Based on extractItemTypesFilterQuery
export const extractTicketFromQueryRequestParams = (
  settingsStore: SettingsStore,
  query: WorkQueueRequestFilters
): TicketSubTypeOption[] | null => {
  const result: TicketSubTypeOption[] = [];
  const itemCategoriesOptions: TicketSubTypeOption[] = getItemCategoriesOptions(settingsStore);

  // Handle reportTypes
  if (query.reportTypes) {
    query.reportTypes.forEach((reportType) => {
      switch (reportType) {
        case ReportType.Oral:
          result.push(
            itemCategoriesOptions.find((i) => i.value === TICKET_TYPE_ORAL_ONCO_REPORT_ID)
          );
          break;
        case ReportType.Distress:
          result.push(
            itemCategoriesOptions.find((i) => i.value === TICKET_TYPE_SYMPTOM_ASSESSMENT_REPORT_ID)
          );
          break;
        case ReportType.CallbackRequestTickets:
          result.push(itemCategoriesOptions.find((i) => i.value === TICKET_TYPE_CALLBACK_ID));
          break;
      }
    });
  }

  // Handle itemCategories
  if (query.itemCategories) {
    query.itemCategories.forEach((category) => {
      switch (category) {
        case ItemCategory.EpisodeTasks:
          result.push({
            label: itemCategoriesMap[TICKET_TYPE_EPISODES_ID].label,
            value: TICKET_TYPE_EPISODES_ID.toString(),
            parentId: null,
            parentName: null
          });
          break;
        case ItemCategory.NonEpisodeTasks:
          result.push({
            label: itemCategoriesMap[TICKET_TYPE_NON_EPISODES_ID].label,
            value: TICKET_TYPE_NON_EPISODES_ID.toString(),
            parentId: null,
            parentName: null
          });
          break;
        case ItemCategory.OverdueSymptomReports:
          result.push({
            label: itemCategoriesMap[TICKET_TYPE_OVERDUE_ID].label,
            value: TICKET_TYPE_OVERDUE_ID.toString(),
            parentId: null,
            parentName: null
          });
          break;
        case ItemCategory.OverdueOralReports:
          result.push({
            label: itemCategoriesMap[TICKET_TYPE_ORAL_ONCO_OVERDUE_ID].label,
            value: TICKET_TYPE_ORAL_ONCO_OVERDUE_ID.toString(),
            parentId: null,
            parentName: null
          });
          break;
      }
    });
  }

  if (query.ticketTypes) {
    const ticketTypes = convertTicketTypeQueryToFilters(query.ticketTypes);
    result.push(...ticketTypes);
  }

  return result.length ? result : null;
};
