// @ts-strict-ignore

import { FC, useCallback, useState } from 'react';

import { observer } from 'mobx-react';
import { Row } from 'react-table';

import { useMount } from 'react-use';

import useNetworkLoading from 'mobx/hooks/useNetworkLoading';
import { useStores } from 'mobx/hooks/useStores';

import { PracticeManagerFetcher } from 'fetchers/PracticeManagerFetcher';

import networkLabelService from 'services/networkLabelService';

import { getNameWithCredentials } from 'utils/ClinicianCredentialUtils';
import { formatDate } from 'utils/DateUtils';
import { CliniciansSupportedFilters } from 'utils/filtersUtils';
import { omitFalsyValuesFromQuery } from 'utils/serverFiltersUtils';

import { getCurrentPageByUrl, getCurrentSortByUrl, getRecordsPerPageByUrl } from 'utils/urlUtils';

import { API_URLS } from 'constants/apiUrls';

import Doctor, { ClinicianUserStatus } from 'models/Doctor';

import {
  CLINICIAN_LIST_FILTERS_LOCAL_STORAGE_KEY,
  PracticeUsersFilters
} from 'views/Filters/PracticeUsersFilters';
import { useGetPersistentFilters } from 'views/Filters/useFilters';
import AccountModal from 'views/Modals/AccountModal';
import { DeactivatePopup } from 'views/Modals/DeactivatePopup';
import { DoctorSignUpModal } from 'views/Pages/DoctorSignup/DoctorSignUpModal';
import {
  defaultPmUsersFilters,
  PM_USERS_PAGE_SIZE
} from 'views/Pages/PracticeManagement/ManagePracticeUsersPage/ManagePracticeUsersPage.constants';

import 'views/Pages/PracticeManagement/ManagePracticeUsersPage/ManagePracticeUsersPage.scss';

import {
  PracticeManagerUsersFilters,
  PracticeManagerUsersSortBy,
  PracticeManagerUsersSortParams,
  PracticeUserRow
} from 'views/Pages/PracticeManagement/ManagePracticeUsersPage/ManagePracticeUsersPage.types';

import { UserStatusCell } from 'views/Pages/PracticeManagement/ManagePracticeUsersPage/UserStatusCell';

import DefaultEmptyTableView from 'views/Widgets/DefaultEmptyTableView';

import Icon from 'components/Icons/Icon';
import FixedLoader from 'components/Loaders/FixedLoader';
import {
  defaultDatePropertyCompare,
  defaultStringPropertyCompare,
  PagedTable,
  PaginationLocation,
  SortOptions,
  TableCellParams,
  TableColumn
} from 'components/Table';

import { OutlinedIconButton } from 'components/UIkit/atoms/Button';

export const ManagePracticeUsersPage: FC = () => {
  const { cliniciansStore, constantsStore } = useStores();
  const [usersRowData, setUsersRowsData] = useState<PracticeUserRow[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isClinicianSignUpOpen, setIsClinicianSignUpOpen] = useState(false);
  const [isClinicianEditOpen, setIsClinicianEditOpen] = useState(false);
  const [isDeactivateOpen, setDeactivateOpen] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState<Doctor>();
  const defaultFilters: CliniciansSupportedFilters =
    useGetPersistentFilters(CLINICIAN_LIST_FILTERS_LOCAL_STORAGE_KEY) || defaultPmUsersFilters;
  const [filters, setFilters] = useState<CliniciansSupportedFilters>(defaultFilters);

  const handleFetchUsersRowsData = async (currentFilters: CliniciansSupportedFilters = filters) => {
    const formattedFilters: PracticeManagerUsersFilters = {
      searchTerm: currentFilters.searchTerm,
      userStatuses: currentFilters.userStatuses?.map((status) => status.value),
      userTypes: currentFilters.userTypes?.map((type) => type.value),
      credentialIds: currentFilters.credentialIds?.map((credential) => credential.value)
    };

    const sortParams = (getCurrentSortByUrl() as PracticeManagerUsersSortParams) || {
      sortBy: PracticeManagerUsersSortBy.Date,
      sortAsc: false
    };

    const { items, totalItems, totalPages } =
      await PracticeManagerFetcher.fetchPracticeManagerUsers({
        pageNumber: getCurrentPageByUrl(),
        recordsPerPage: getRecordsPerPageByUrl() || PM_USERS_PAGE_SIZE,
        ...omitFalsyValuesFromQuery(formattedFilters),
        ...sortParams
      });

    setUsersRowsData(items);
    setTotalItems(totalItems);
    setTotalPages(totalPages);
  };

  useMount(handleFetchUsersRowsData);

  const syncUsersAfterUpdateClinician = () => {
    handleFetchUsersRowsData();
    closeEditClinicianModal();
  };

  const closeEditClinicianModal = () => {
    setSelectedDoctor(undefined);
    setIsClinicianEditOpen(false);
  };

  const editDoctor = async (doctorId: number) => {
    const [doctor] = await cliniciansStore.fetchCliniciansByIds([doctorId]);
    setSelectedDoctor(doctor);
    setIsClinicianEditOpen(true);
  };

  const deactivateClinician = async () => {
    if (!selectedDoctor) {
      return;
    }

    try {
      setDeactivateOpen(false);
      await cliniciansStore.deactivateClinician(selectedDoctor.id);
    } finally {
      setIsClinicianEditOpen(false);
      handleFetchUsersRowsData();
    }
  };

  const activateClinician = async (id: number) => {
    await cliniciansStore.activateClinician(id);
    handleFetchUsersRowsData();
  };

  const getIsActivating = (id: number) => {
    return networkLabelService.isLoading(API_URLS.ACTIVATE_CLINICIAN(id));
  };

  const getColumns = useCallback(
    ({
      activate,
      getIsActivating
    }: {
      activate: (id: number) => void;
      getIsActivating: (id: number) => boolean;
    }): TableColumn<PracticeUserRow>[] => [
      {
        Header: 'User',
        accessor: 'name',
        sortType: defaultStringPropertyCompare('name.fullName'),
        Cell: (row: { value: { firstName: string; lastName: string; credentialId?: number } }) => {
          const { credentialId, lastName, firstName } = row.value;
          const credentialText = constantsStore.getClinicianCredentialById(credentialId)?.text;
          const nameWithCredentials = getNameWithCredentials(
            `${firstName} ${lastName}`,
            credentialText || null
          );

          return <span>{nameWithCredentials}</span>;
        }
      },
      {
        Header: 'Mobile Phone',
        accessor: 'phone'
      },
      {
        Header: 'Email',
        accessor: 'email',
        sortType: defaultStringPropertyCompare('email')
      },
      {
        Header: 'User Type',
        accessor: 'type'
      },
      {
        Header: 'User Status',
        accessor: 'userStatus',
        Cell: (cell: TableCellParams<PracticeUserRow>) => (
          <UserStatusCell activate={activate} getIsActivating={getIsActivating} {...cell} />
        )
      },
      {
        Header: 'Join Date',
        accessor: 'joinDate',
        defaultSort: SortOptions.desc,
        sortType: defaultDatePropertyCompare('joinDate'),
        Cell: (cell: TableCellParams<PracticeUserRow>) => {
          return <>{formatDate(cell.value, 'M/D/YYYY')}</>;
        }
      }
    ],
    [constantsStore]
  );

  const handleFilterChange = useCallback((newFilters: CliniciansSupportedFilters) => {
    setFilters(newFilters);
  }, []);

  const showDeactivateButton = selectedDoctor?.userStatus === ClinicianUserStatus.ACTIVE;
  const isFetchingUsers = useNetworkLoading(API_URLS.PRACTICE_MANAGER_USERS) || !usersRowData;
  const isDeactivating = useNetworkLoading(API_URLS.DEACTIVATE_CLINICIAN(selectedDoctor?.id));

  return (
    <div className="manage-practice-users-page">
      <DoctorSignUpModal
        close={() => {
          setIsClinicianSignUpOpen((prev) => !prev);
          handleFetchUsersRowsData();
        }}
        isOpen={isClinicianSignUpOpen}
      />

      {selectedDoctor && (
        <DeactivatePopup
          clinician={selectedDoctor}
          isOpen={isDeactivateOpen}
          handleClose={() => setDeactivateOpen(false)}
          primaryActionProps={{
            text: 'Deactivate User',
            disabled: isDeactivating,
            onClick: deactivateClinician
          }}
          secondaryActionProps={{
            text: 'Cancel',
            disabled: isDeactivating,
            onClick: () => setDeactivateOpen(false)
          }}
        />
      )}

      <AccountModal
        isOpen={isClinicianEditOpen}
        onCancel={closeEditClinicianModal}
        onSaved={syncUsersAfterUpdateClinician}
        secondaryAction={{
          onClick: () => setDeactivateOpen(true),
          isVisible: showDeactivateButton,
          text: 'Deactivate',
          disabled: false
        }}
        doctor={selectedDoctor}
      />

      <div className="top-tab-section">
        <OutlinedIconButton
          icon={<Icon.Plus />}
          onClick={() => setIsClinicianSignUpOpen((prev) => !prev)}
          variant="secondary"
          testHook="add-user-button"
        >
          Add User
        </OutlinedIconButton>
      </div>

      <PracticeUsersFilters
        filters={filters}
        onFilterChange={handleFilterChange}
        fetchDataAfterFilterChange={handleFetchUsersRowsData}
      />

      {isFetchingUsers && <FixedLoader />}

      <PagedTable
        totalPages={totalPages}
        isLoading={isFetchingUsers}
        paginationLocation={[PaginationLocation.TOP, PaginationLocation.BOTTOM]}
        columns={getColumns({ activate: activateClinician, getIsActivating })}
        rowData={usersRowData}
        totalItemsCount={totalItems}
        rowAction={(row: Row<PracticeUserRow>) => editDoctor(row.original.id)}
        dashedRowRule={(row) => row.userStatus === ClinicianUserStatus.INACTIVE}
        emptyTableView={<DefaultEmptyTableView />}
        onManualPageChange={() => handleFetchUsersRowsData()}
        onManualSort={() => handleFetchUsersRowsData()}
      />
    </div>
  );
};

export default observer(ManagePracticeUsersPage);
