import React, { FC } from 'react';

import { OutlinedButton } from 'components/UIkit/atoms/Button';
import {
  FormLabeledCheckbox,
  FormSubtextLabeledCheckbox,
  LabeledCheckbox
} from 'components/UIkit/atoms/Checkbox';
import { ExternalLabeledCheckboxProps } from 'components/UIkit/atoms/Checkbox/LabeledCheckbox';
import { SecondaryAction } from 'components/UIkit/atoms/Modal/Modal';

interface Props {
  action: SecondaryAction;
  containerTestHook?: string;
}

const ModalSecondaryAction: FC<Props> = ({ action, containerTestHook }) => {
  if (!('isVisible' in action)) {
    //isVisible default value is true
    action.isVisible = true;
  }

  const { type, isVisible, ...rest } = action;

  const getTestHook = () => {
    if (action.testHook) {
      return action.testHook;
    }

    if (containerTestHook) {
      return `${containerTestHook}-secondary-action`;
    }

    return '';
  };

  const actionTestHook = getTestHook();

  return (
    <>
      {isVisible && (
        <>
          {type === 'button' && (
            <OutlinedButton
              onClick={action.onClick}
              disabled={action.disabled}
              size="medium"
              color="error"
              testHook={actionTestHook}
            >
              {action.text}
            </OutlinedButton>
          )}

          {type === 'labeled-checkbox' && (
            <FormLabeledCheckbox
              label={action.label}
              name={action.name || ''}
              testHook={actionTestHook}
            />
          )}

          {type === 'controlled-labeled-checkbox' && (
            <LabeledCheckbox
              {...(rest as ExternalLabeledCheckboxProps)}
              testHook={actionTestHook}
            />
          )}

          {type === 'subtext-labeled-checkbox' && (
            <FormSubtextLabeledCheckbox
              id={action.id}
              label={action.label}
              name={action.name}
              subText={action.subText}
              testHook={actionTestHook}
            />
          )}
        </>
      )}
    </>
  );
};

export default ModalSecondaryAction;
