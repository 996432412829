import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action =
  | AnalyticEventAction.Open
  | AnalyticEventAction.Save
  | AnalyticEventAction.Delete
  | AnalyticEventAction.Cancel;

/** Relevant for AnalyticEventAction.Save actions. */
type Value = 'create' | 'edit';

interface Properties {
  action: Action;
  value?: Value;
  is_shared?: boolean;
  share_added?: boolean;
  share_removed?: boolean;
  name_changed?: boolean;
}

export const trackFilterSetModalAnalyticsEvent = ({
  action,
  ...optionalProperties
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.FilterSetModal,
    properties: { action, ...optionalProperties }
  });
};
