import { AnalyticsService, AnalyticEventAction, AnalyticEventName } from '../';

type Action = AnalyticEventAction.Click | AnalyticEventAction.Select;

interface Properties {
  action: Action;
  patient_id?: number;
  value?: string;
}

export const trackStatusMenuAnalyticsEvent = ({
  action,
  patient_id,
  ...optionalProps
}: Properties) => {
  AnalyticsService.trackEvent<Properties>({
    name: AnalyticEventName.StatusMenu,
    properties: { action, patient_id, ...optionalProps }
  });
};
