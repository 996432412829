import { FC, useCallback, MouseEvent, ReactNode } from 'react';

import { Box } from '@mui/material';
import { css, Palette, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { components, OptionProps } from 'react-select';

import Icon from 'components/Icons/Icon';
import { SimpleTooltip } from 'components/Tooltip';
import { SelectVariant } from 'components/UIkit/atoms/Dropdown/Select.shared';

import { isAddOptionValue } from 'components/UIkit/atoms/Dropdown/SelectUtils';

function useOnEditClick(props: any) {
  const { onEditClick } = props.selectProps;
  const { data } = props;

  return useCallback(
    (event: MouseEvent<any>) => {
      if (onEditClick) {
        onEditClick(data);
        event.stopPropagation(); // prevent selecting item when onEditClick is called
      }
    },
    [onEditClick, data]
  );
}

const AddOptionContent: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box display="flex">
      <Box mr={4}>
        <Icon.CirclePlus />
      </Box>
      <Typography variant="form-text">{children}</Typography>
    </Box>
  );
};

export const DefaultMenuOption: FC<OptionProps<any, boolean>> = ({ children, ...props }) => {
  const onEditClick = useOnEditClick(props);
  const isAddOption = isAddOptionValue(props.data.value);

  return (
    <StyledMenuOption {...props}>
      <StyledIconWrapper>
        {((props.data.isDisabled && props.data.disabledOptions?.isSelected) ||
          props.isSelected) && <Icon.CheckMark />}
      </StyledIconWrapper>

      <Box display="flex" flexGrow={1}>
        {isAddOption ? (
          <AddOptionContent>{children}</AddOptionContent>
        ) : (
          <SimpleTooltip
            title={<Box p={20}>{props.data.disabledOptions?.tooltipText}</Box>}
            disabled={!props.data.isDisabled || !props.data.disabledOptions?.tooltipText}
            placement="right"
            shouldTruncate={false}
          >
            <Typography variant="form-text">{children}</Typography>
          </SimpleTooltip>
        )}
      </Box>
      {props.data.isEditable && (
        <Typography variant="body2" className="menu-option-edit" onClick={onEditClick}>
          EDIT
        </Typography>
      )}
      {/* add here tag by data.tag? */}
    </StyledMenuOption>
  );
};

// get colorSet by precedence
type OptionColorSet = {
  color: string;
  editColor: string;
  backgroundColor: string;
};

function getOptionColorSet(
  variant: SelectVariant,
  isSelected: boolean,
  isFocused: boolean,
  isDisabled: boolean,
  palette: Palette
): OptionColorSet {
  if (isDisabled) {
    return {
      color: palette.text.disabled,
      editColor: palette.text.disabled,
      backgroundColor: palette.natural.white
    };
  }

  if (isFocused) {
    if (variant === 'primary') {
      return {
        color: isSelected ? palette.primary.main : palette.text.primary,
        editColor: palette.primary.main,
        backgroundColor: palette.natural.hover
      };
    }

    return {
      color: isSelected ? palette.secondary.main : palette.text.primary,
      editColor: palette.secondary.main,
      backgroundColor: palette.natural.hover
    };
  }

  if (isSelected) {
    if (variant === 'primary') {
      return {
        color: palette.primary.main,
        editColor: palette.primary.main,
        backgroundColor: palette.natural.white
      };
    }
    return {
      color: palette.secondary.main,
      editColor: palette.secondary.main,
      backgroundColor: palette.natural.white
    };
  }

  return {
    color: palette.text.primary,
    editColor: palette.primary.main,
    backgroundColor: palette.natural.white
  };
}

export const StyledMenuOption: FC<OptionProps<any, boolean>> = (props) => (
  <div data-test-hook={`select-option-${props.data.value}`}>
    <BaseStyledMenuOption {...props} />
  </div>
);

const BaseStyledMenuOption: FC<OptionProps<any, boolean>> = styled(components.Option, {
  shouldForwardProp: () => true // react-select "theme" object  should be passed down to react-select component
})((props: OptionProps<any, boolean>) => {
  const { isSelected, isFocused, isDisabled } = props;
  const isSelectedOrAdd = isSelected || isAddOptionValue(props.data.value);

  // when styling react-select component - use muiTheme instead of react-select theme
  const { palette, spacing } = props.selectProps.muiTheme;
  const { color, editColor, backgroundColor } = getOptionColorSet(
    props.selectProps.variant || 'primary',
    isSelectedOrAdd,
    isFocused,
    isDisabled,
    palette
  );

  return css`
    display: flex;
    align-items: center;
    cursor: ${isDisabled ? 'initial' : 'pointer'};
    padding: ${spacing(8, 28, 8, 8)};
    color: ${color};
    background-color: ${backgroundColor};
    // for edit button
    .menu-option-edit {
      color: ${editColor};
    }
    // for icons
    svg path {
      stroke: ${backgroundColor};
    }

    ${!isDisabled &&
    css`
      &:active {
        background-color: ${palette.natural.hover};
      }
    `}
  `;
});

const StyledIconWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    width: 12px; // replace by icon size from theme (wait for EH-3989)
    min-width: 12px; // same as above (should take space even when mark icon not displayed)
    margin-right: ${theme.spacing(2)}; // 4px (wait for EH-4187)
  `
);
