import React, { FC, useEffect } from 'react';

import { Box } from '@mui/material';
import { observer } from 'mobx-react';

import { useHistory, useParams } from 'react-router-dom';
import { workQueueTestSelectors } from 'tests/models/pages/work-queue/work-queue-page.selectors';

import { workQueueTabs } from 'views/WorkQueue/WorkQueue.constants';
import { WorkQueueTab } from 'views/WorkQueue/WorkQueue.types';

import { WorkQueueOpenItemsView } from 'views/WorkQueue/WorkQueueOpenItemsView/WorkQueueOpenItemsView';
import { WorkQueueResolvedItemsView } from 'views/WorkQueue/WorkQueueResolvedItemsView/WorkQueueResolvedItemsView';

import FixedLoader from 'components/Loaders/FixedLoader';

export const WorkQueuePage: FC = observer(() => {
  const { activeTab } = useParams<{ activeTab: WorkQueueTab }>();
  const history = useHistory();
  const shouldRedirect = activeTab && !workQueueTabs.includes(activeTab);

  useEffect(
    function redirectToOpenItems() {
      if (shouldRedirect) {
        history.replace('/work-queue');
      }
    },
    [history, shouldRedirect]
  );

  if (shouldRedirect) {
    return <FixedLoader />;
  }

  return (
    <Box data-test-hook={workQueueTestSelectors.page}>
      {activeTab === WorkQueueTab.ResolvedItems ? (
        <WorkQueueResolvedItemsView />
      ) : (
        <WorkQueueOpenItemsView />
      )}
    </Box>
  );
});
