import { useCallback, useMemo } from 'react';

import debounce from 'debounce-promise';

import { useStores } from 'mobx/hooks/useStores';

import { WORKQUEUE_FILTERS_LOCAL_STORAGE_KEY } from 'views/Filters/filters.constants';
import { SearchFiltersType, TicketsFiltersType } from 'views/Filters/filters.types';

import { useSetPersistFilters } from 'views/Filters/useFilters';

export const useWqFilters = () => {
  const { ticketFiltersStore, workQueueStore } = useStores();

  const debouncedFetch = useMemo(() => {
    return debounce(async () => {
      ticketFiltersStore.setUpdatingFilters(true);
      await workQueueStore.resetAndFetchDefaultWq();
      ticketFiltersStore.setUpdatingFilters(false);
    }, 400);
  }, [workQueueStore, ticketFiltersStore]);

  const handleChange = useCallback(
    (filters: TicketsFiltersType) => {
      ticketFiltersStore.replaceFilters(filters);
      debouncedFetch();
    },
    [debouncedFetch, ticketFiltersStore]
  );

  const {
    updateFiltersByKey,
    updateAllFilters,
    compareWithCurrentFilters,
    compareFilters,
    hasAnyActiveFilter
  } = useSetPersistFilters<SearchFiltersType>(ticketFiltersStore.filters, handleChange, {
    localStorageKey: WORKQUEUE_FILTERS_LOCAL_STORAGE_KEY,
    excludeFieldKeys: ['taskSearchTerm', 'searchTerm', 'fromDate', 'toDate', 'filterSet']
  });

  return {
    filters: ticketFiltersStore.filters,
    updateFiltersByKey,
    updateAllFilters,
    compareFilters,
    compareWithCurrentFilters,
    hasAnyActiveFilter
  };
};
