import { FC, useMemo, useState } from 'react';

import { Box, css, styled } from '@mui/material';

import { AnalyticEventAction } from 'analytics/AnalyticEventAction';
import { Dialogs } from 'analytics/events/dialog';
import { trackStatusMenuAnalyticsEvent } from 'analytics/events/status-menu';
import { workQueueTestSelectors } from 'tests/models/pages/work-queue/work-queue-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import Icon from 'components/Icons/Icon';

import { Tooltip, TooltipSelect, ITooltipOption } from 'components/Tooltip';
import { TooltipTrigger } from 'components/Tooltip/Tooltip.types';
import { TextButton } from 'components/UIkit/atoms/Button';

import { MessageDialog } from 'components/UIkit/atoms/Dialog';
import { Text } from 'components/UIkit/atoms/Text';

import { FilterSetMetadata } from './filters.types';

const FILTER_SET_OPTION_CLASS_NAME = 'filter-set-select-option';
const FILTER_SET_NAME_CLASS_NAME = 'filter-set-name';
const FILTER_SET_OPTION_CONTENT_CLASS_NAME = 'filter-set-option-content';

const NO_FILTER_SET_SELECTED = Symbol();

interface Props {
  activeFilterSetMetadata: FilterSetMetadata | null;
  filterSetsMetadata: FilterSetMetadata[];
  onFilterSetSelect: (filterSetId: number) => void;
  onSelectEdit: (filterSetId: number) => void;
  onSelectCreate: () => void;
  onSelectClear: () => void;
  isModified: boolean;
  isLoading: boolean;
  hasAnyActiveFilter: boolean;
}

export const FilterSetDropdown: FC<Props> = ({
  activeFilterSetMetadata,
  filterSetsMetadata,
  onFilterSetSelect,
  onSelectEdit,
  onSelectCreate,
  onSelectClear,
  isModified,
  isLoading,
  hasAnyActiveFilter
}) => {
  const { userStore } = useStores();
  const isManager = userStore.isManager;
  const [isOpen, setIsOpen] = useState(false);
  const [pendingConfirmationId, setPendingConfirmationId] = useState<
    number | typeof NO_FILTER_SET_SELECTED | null
  >(null);

  const options = useMemo((): ITooltipOption[] => {
    const baseOptions: ITooltipOption[] = [
      {
        text: 'Save As...',
        id: 'save_as',
        disabled: !hasAnyActiveFilter,
        testHook: workQueueTestSelectors.openItemsTab.filterSetsMenuOptions.saveAsOption,
        onClick: () => {
          setIsOpen(false);
          onSelectCreate();
          trackStatusMenuAnalyticsEvent({
            action: AnalyticEventAction.Click,
            value: 'Save As...'
          });
        }
      },
      {
        text: 'Clear All Filters',
        id: 'clear_all',
        testHook: workQueueTestSelectors.openItemsTab.filterSetsMenuOptions.clearAllFiltersOption,
        disabled: !hasAnyActiveFilter,
        onClick: () => {
          setIsOpen(false);
          if (isModified && activeFilterSetMetadata) {
            setPendingConfirmationId(NO_FILTER_SET_SELECTED);
          } else {
            onSelectClear();
          }
          trackStatusMenuAnalyticsEvent({
            action: AnalyticEventAction.Click,
            value: 'Clear All Filters'
          });
        }
      }
    ];

    const filterSetOptions = filterSetsMetadata.map(
      (filterSet, index): ITooltipOption => ({
        id: `filter_set_${filterSet.id}`,
        groupHeader: index === 0 && 'Filter Sets',
        className: FILTER_SET_OPTION_CLASS_NAME,
        text: (
          <div className={FILTER_SET_OPTION_CONTENT_CLASS_NAME}>
            <span className={FILTER_SET_NAME_CLASS_NAME}>
              <span className="text-truncate">{filterSet.name}</span>
              {filterSet.isShared && <StyledShareIcon fontSize="small" />}
            </span>
            {(isManager || !filterSet.isShared) && (
              <TextButton
                onClick={(event) => {
                  event.stopPropagation();
                  setIsOpen(false);
                  onSelectEdit(filterSet.id);
                }}
                testHook={workQueueTestSelectors.openItemsTab.filterSetsMenuOptions.setOptionEdit(
                  filterSet.id
                )}
              >
                EDIT
              </TextButton>
            )}
          </div>
        ),
        isSelected: activeFilterSetMetadata?.id === filterSet.id,
        onClick: (a) => {
          setIsOpen(false);
          if (isModified) {
            setPendingConfirmationId(filterSet.id);
          } else {
            onFilterSetSelect(filterSet.id);
          }
        },
        testHook: workQueueTestSelectors.openItemsTab.filterSetsMenuOptions.setOption(filterSet.id)
      })
    );

    return [...baseOptions, ...filterSetOptions];
  }, [
    filterSetsMetadata,
    isManager,
    onSelectCreate,
    onSelectEdit,
    onFilterSetSelect,
    activeFilterSetMetadata,
    hasAnyActiveFilter,
    onSelectClear,
    isModified
  ]);

  const closeAbandonChangesDialog = () => {
    setPendingConfirmationId(null);
  };

  const handleDismissChanges = () => {
    if (pendingConfirmationId === NO_FILTER_SET_SELECTED) {
      onSelectClear();
    } else {
      onFilterSetSelect(pendingConfirmationId);
    }
    closeAbandonChangesDialog();
  };

  return (
    <>
      <MessageDialog
        testHook={workQueueTestSelectors.openItemsTab.filtersAbandonChangesDialog}
        id={Dialogs.AbandonUnsavedChanges}
        isOpen={pendingConfirmationId !== null}
        handleClose={closeAbandonChangesDialog}
        title="Filter Changes Will Be Lost"
        primaryActionProps={{
          text: 'Dismiss & Proceed',
          onClick: handleDismissChanges
        }}
        secondaryActionProps={{
          text: 'Cancel',
          onClick: closeAbandonChangesDialog
        }}
      >
        This Filter Set has been customized. <br />
        <br />
        To save these changes: click ‘Cancel’ and then ‘Save As’ from the Filter Set menu.
      </MessageDialog>
      <Box>
        <Tooltip
          testHook={workQueueTestSelectors.openItemsTab.filterSetsSelectMenu}
          trigger={TooltipTrigger.CLICK}
          controller={{
            visible: isOpen,
            onClickOutside: () => {
              setIsOpen(false);
            }
          }}
          label={
            <StyledActiveFilterSetLabel
              data-test-hook={workQueueTestSelectors.openItemsTab.filterSetsSelectLabel}
              variant="h3"
              color="secondary"
              className={isOpen ? 'active' : ''}
              onClick={(event) => {
                setIsOpen(!isOpen);
              }}
            >
              <Text variant="h3" isEllipsis flex="0 1 auto">
                {activeFilterSetMetadata?.name || 'My Filters'}
              </Text>
              {!isLoading && isModified && activeFilterSetMetadata && (
                <StyledModifiedIndicator
                  title={
                    activeFilterSetMetadata ? 'Filter set has unsaved changes' : 'Unsaved filters'
                  }
                />
              )}
              <StyledCaret />
            </StyledActiveFilterSetLabel>
          }
        >
          <StyledTooltipSelect options={options} />
        </Tooltip>
      </Box>
    </>
  );
};

const StyledTooltipSelect = styled(TooltipSelect)(
  ({ theme }) => css`
    .${FILTER_SET_NAME_CLASS_NAME} {
      min-width: 0;
      display: flex;
      flex: 1;
      align-items: center;
    }

    .${FILTER_SET_OPTION_CONTENT_CLASS_NAME} {
      display: flex;
      width: 200px;
      min-width: 0;
    }

    .${FILTER_SET_OPTION_CLASS_NAME} button {
      display: none;
      padding-left: ${theme.spacing(4)};
    }

    .${FILTER_SET_OPTION_CLASS_NAME}:hover button {
      display: block;
    }
  `
);

const StyledCaret = styled(Icon.Chevron)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(8)};
    width: 22px;
  `
);

const StyledShareIcon = styled(Icon.Share)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(4)};
  `
);

const StyledModifiedIndicator = styled('div')(
  ({ theme }) => css`
    position: relative;
    width: 6px;
    height: 16px;
    margin-left: ${theme.spacing(4)};

    &:after {
      content: ' ';
      background-color: ${theme.palette.error.dark};
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  `
);

const StyledActiveFilterSetLabel = styled(Text)(
  ({ theme }) => css`
    color: ${theme.palette.secondary.dark};
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;

    &,
    span {
      color: ${theme.palette.secondary.dark};
    }

    &.active span,
    &:hover span {
      color: ${theme.palette.secondary.main};
    }

    &.active svg path,
    &:hover svg path {
      fill: ${theme.palette.secondary.main};
    }
  `
);
