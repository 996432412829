import { KeyboardEventHandler, useCallback, useRef, useState } from 'react';

import { Theme } from '@mui/material/styles';

import { SelectComponentsConfig } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import {
  ActionMeta,
  GroupBase,
  InputActionMeta,
  MenuPlacement,
  Options
} from 'react-select/dist/declarations/src/types';

import { Testable } from 'utils/TypeUtils';

import { isModalOpen } from 'utils/ViewUtils';

import { SelectOption } from 'models/SelectOption';

import { theme } from 'components/UIkit/theme';

import { defaultSelectComponents } from './Select/ReactSelectComponents';

import { reactSelectStyle } from './Select.styles';
import { FormProps } from './withForm.shared';

// used by StyledSelect.scss removed when not used anymore
const CLASS_NAME_PREFIX = 'styled-select';

export interface ISelectOption<V> {
  value?: V;
  label: string;
  isDisabled?: boolean;
  disabledOptions?: { isSelected?: boolean; tooltipText?: string };
  isEditable?: boolean;
  isParent?: boolean;
  options?: any[]; // for grouping
}

export type SelectComponents = SelectComponentsConfig<any, boolean, any>;
// TODO: fix to use generics not any
export type SelectOnChangeHandler = (
  value: SelectOption<any> | SelectOption<any>[] | null,
  actionMeta: ActionMeta<ISelectOption<any>>,
  eventKey: string | null
) => void;

// the following interface set the allowed react-select props - see https://react-select.com/props

// these props should only be used *internally* by the UIKit
export interface ReactSelectInternalProps {
  components?: SelectComponents;
  isMulti?: boolean;
  autoFocus?: boolean;
  isSearchable?: boolean;
}

// react-select props - exposed to the UIKit user
export interface ReactSelectExternalProps {
  placeholder?: string;
  onChange?: SelectOnChangeHandler;
  components?: SelectComponents;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void; // TODO: move to CreatableSelect interface
  inputValue?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  getOptionValue?: (option: ISelectOption<any>) => string;
  getOptionLabel?: (option: ISelectOption<any>) => any; //was ReactNode, but not correspond to react-select types;
  filterOption?:
    | ((option: FilterOptionOption<ISelectOption<any>>, inputValue: string) => boolean)
    | null;
  backspaceRemovesValue?: boolean;
  autoFocus?: boolean;
  hideSelectedOptions?: boolean;
  closeMenuOnSelect?: boolean;
  isOptionDisabled?: (
    option: ISelectOption<any>,
    selectValue: Options<ISelectOption<any>>
  ) => boolean;
}

export type SelectVariant = 'primary' | 'secondary';

// props which are not provided by react-select - exposed to the UIKit user
export interface SelectProps {
  label?: string;
  labelTooltip?: string;
  isError?: boolean;
  errorMessage?: string;
  openMenuUp?: boolean;
  variant?: SelectVariant;
}

export interface BaseSelectProps extends SelectProps, ReactSelectExternalProps, Testable {
  // react-select props
  value: any;
  options: Array<ISelectOption<any>>;
  isNarrow?: boolean; // use different height when using in tables
  // local props
  sortAlphabetically?: boolean;
  addText?: string;
  onEditClick?: (options?: ISelectOption<any>) => void;
  classNamePrefix?: string;
}

export interface AsyncBaseSelectProps extends SelectProps, ReactSelectExternalProps, Testable {
  // react-select props - https://react-select.com/async
  value: any;
  loadOptions: (inputValue: string, callback: (options: Options<any>) => void) => void;
  defaultOptions?: Options<any> | boolean;
  displaySelectedSummary?: boolean;
}

declare module 'react-select/dist/declarations/src/Select' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    // custom react-select props. see https://react-select.com/typescript#custom-select-props
    // for usage - see useCommonReactSelectProps below
    // TODO: once CreatableSelect is removed from StyledSelect.tsx, these props can become mandatory
    label?: string;
    isFocused?: boolean;
    isError?: boolean;
    isNarrow?: boolean;
    muiTheme?: Theme;
    onEditClick?: (options?: ISelectOption<any>) => void;
    variant?: SelectVariant;

    //for sub ticket types dropdowns in create tickets page
    isSub?: boolean;
  }
}

// common react-select props - same treatment for all select types
export function useCommonReactSelectProps({
  isSearchable = true,
  isClearable,
  isRequired,
  placeholder = '',
  openMenuUp,
  label,
  isError,
  components,
  testHook,
  onChange,
  onKeyDown,
  ...rest
}: SelectProps &
  ReactSelectInternalProps &
  ReactSelectExternalProps &
  Partial<FormProps> &
  Testable) {
  const [isFocused, setIsFocused] = useState(false);
  const eventKeyRef = useRef<string | null>(null);

  const handleChange = useCallback(
    (newValue: SelectOption<any> | SelectOption<any>[], action: ActionMeta<ISelectOption<any>>) => {
      // this is a temp fix which was supposed to be solved by react-select v5.
      // see EH-2390, EH4189
      if (Array.isArray(newValue) && newValue.length === 0) {
        newValue = null;
      }
      onChange && onChange(newValue, action, eventKeyRef.current);
      eventKeyRef.current = null;
    },
    [onChange]
  );

  if (isClearable === undefined) {
    // unless specified, by default isClearable=true, unless isRequired=true
    isClearable = !isRequired;
  }

  const detectKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    eventKeyRef.current = event.key;
    onKeyDown && onKeyDown(event);
  };

  return {
    // custom props - can be accessed in react-select components using selectProps.
    label,
    isFocused,
    isError,
    muiTheme: theme,
    // react select props - https://react-select.com/props
    isSearchable,
    isClearable,
    placeholder: placeholder,
    menuShouldScrollIntoView: false,
    classNamePrefix: CLASS_NAME_PREFIX,
    menuPlacement: openMenuUp ? ('top' as MenuPlacement) : ('auto' as MenuPlacement), // add override support for AsyncSelect (icd-10)
    onFocus: () => {
      setIsFocused(true);
    },
    onBlur: () => {
      setIsFocused(false);
    },
    onChange: handleChange,
    'aria-labelledby': testHook || label, // temp testing solution, so react-select-event may work (need to get by label, but must add any testHook!)
    'aria-label': testHook || label, // temp testing solution, so react-select-event may work (need to get by label, but must add any testHook!)
    components: { ...defaultSelectComponents, ...components },
    styles: reactSelectStyle,
    menuPortalTarget: isModalOpen() ? document.body : null,
    ...rest,
    onKeyDown: detectKeyDown
  };
}

export function getSelectContainerProps({
  label,
  testHook,
  labelTooltip,
  isError,
  errorMessage,
  variant
}: SelectProps & Testable) {
  return { label, testHook, labelTooltip, isError, errorMessage, variant };
}

export const ASYNC_SELECT_LOAD_OPTIONS_DEBOUNCE_DELAY = 400;
