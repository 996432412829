import { FC, useMemo, useState } from 'react';

import { Box } from '@mui/material';

import { observer } from 'mobx-react';

import { pathwayBuilderTestSelectors } from 'tests/models/pages/pathway-builder/pathway-builder-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import { formatDate } from 'utils/DateUtils';

import { isSubStringCaseInsensitive } from 'utils/StringUtils';

import { SelectOption } from 'models/SelectOption';

import SearchBar from 'views/Dashboard/SearchBar';
import {
  DEPRESSION_SCREENING_PATHWAY_ID,
  ICAN_PATHWAY_ID
} from 'views/Pages/PathwayBuilder/PathwayBuilderPage.constants';
import { PathwayType } from 'views/Pages/PathwayBuilder/PathwayEditorView/PathwayEditorView.types';
import {
  pathwayTypeOptions,
  pathwayTypeToName
} from 'views/Pages/PathwayBuilder/PathwaysTabView/PathwayTabView.constants';

import { PathwaysTableRow } from 'views/Pages/PathwayBuilder/PathwaysTabView/PathwayTabView.types';

import DefaultEmptyTableView from 'views/Widgets/DefaultEmptyTableView';

import Icon from 'components/Icons/Icon';

import { SortOptions, TableColumn, Table } from 'components/Table';
import { OutlinedIconButton } from 'components/UIkit/atoms/Button';
import { Autocomplete } from 'components/UIkit/atoms/Dropdown';

interface Props {
  openPathwayEditor: (pathwayId: string) => void;
  onNewPathwayClick: () => void;
}

export const PathwaysTabView: FC<Props> = observer(({ openPathwayEditor, onNewPathwayClick }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [pathwayType, setPathwayType] = useState<SelectOption<PathwayType> | null>(null);
  const { pathwayBuilderStore } = useStores();
  const { pathways, updatedAt } = pathwayBuilderStore;

  const columns: TableColumn<PathwaysTableRow>[] = useMemo(
    () => [
      {
        Header: 'Pathway',
        accessor: 'name',
        defaultSort: SortOptions.asc
      },
      {
        Header: 'Type',
        accessor: (row) => pathwayTypeToName[row.type]
      },
      {
        Header: 'Last Published',
        accessor: 'lastPublished'
      }
    ],
    []
  );

  const filteredPathways = pathways.filter((pathway) => {
    const isPointsBased =
      pathway.id === DEPRESSION_SCREENING_PATHWAY_ID || pathway.id === ICAN_PATHWAY_ID;
    if (isPointsBased) {
      return false;
    }

    if (searchTerm && !isSubStringCaseInsensitive(pathway.name, searchTerm)) {
      return false;
    }

    if (pathwayType?.value && pathway.type !== pathwayType.value) {
      return false;
    }

    return true;
  });

  const rows: PathwaysTableRow[] = filteredPathways.map((pathway) => ({
    id: pathway.id,
    name: pathway.name,
    type: pathway.type,
    lastPublished: formatDate(updatedAt as string, 'MM/DD/YYYY')
  }));

  return (
    <>
      <Box display="flex" gap={20}>
        <Box flex={1}>
          <SearchBar
            searchValue={searchTerm}
            onSearchChanged={(searchTerm) => setSearchTerm(searchTerm)}
            label="Filter by Pathway"
          />
        </Box>

        <Box flex={1}>
          <Autocomplete
            options={pathwayTypeOptions}
            value={pathwayType}
            onChange={(
              selectedType: SelectOption<PathwayType> | SelectOption<PathwayType>[] | null
            ) => {
              setPathwayType(selectedType as SelectOption<PathwayType> | null);
            }}
            label="Pathway Type"
          />
        </Box>
      </Box>

      <OutlinedIconButton
        icon={<Icon.Plus />}
        onClick={onNewPathwayClick}
        my={20}
        variant="secondary"
        testHook={pathwayBuilderTestSelectors.tabs.pathways.newPathwayButton}
      >
        New Pathway
      </OutlinedIconButton>

      <Table
        columns={columns}
        rowData={rows}
        rowAction={(row) => openPathwayEditor(row.original.id)}
        emptyTableView={<DefaultEmptyTableView />}
      />
    </>
  );
});
