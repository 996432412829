import { FC, ReactNode } from 'react';

import { Box, css, Fade, Grid, Modal as MuiModal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Testable } from 'utils/TypeUtils';

import { FilledButton, OutlinedButton } from 'components/UIkit/atoms/Button';
import { ExternalLabeledCheckboxProps } from 'components/UIkit/atoms/Checkbox/LabeledCheckbox';
import ModalSecondaryAction from 'components/UIkit/atoms/Modal/ModalSecondaryAction';

export interface ModalAction extends Testable {
  text: string;
  onClick: () => void;
  disabled: boolean;
  isVisible?: boolean;
}

export interface ButtonSecondaryAction extends ModalAction {
  type: 'button';
}

export interface LabeledCheckboxSecondaryAction extends Testable {
  type: 'labeled-checkbox';
  isVisible?: boolean;
  label: string;
  name: string;
}

export interface ControlledLabeledCheckboxSecondaryAction
  extends ExternalLabeledCheckboxProps,
    Testable {
  type: 'controlled-labeled-checkbox';
  isVisible?: boolean;
}

export interface SubtextLabeledCheckboxSecondaryAction extends Testable {
  type: 'subtext-labeled-checkbox';
  isVisible?: boolean;
  id: string;
  label: string;
  name: string;
  subText: string;
}

export type SecondaryAction =
  | ButtonSecondaryAction
  | LabeledCheckboxSecondaryAction
  | ControlledLabeledCheckboxSecondaryAction
  | SubtextLabeledCheckboxSecondaryAction;

export interface ModalProps extends Testable {
  children: ReactNode;
  isOpen: boolean;
  title: string;
  subtitle?: string;
  confirmActions: ModalAction[];
  closeAction: Omit<ModalAction, 'text'>;
  secondaryAction?: SecondaryAction;
  size?: 'small' | 'medium' | 'large';
  resetDataAfterClose?: () => void;
}

export const Modal: FC<ModalProps> = ({
  isOpen,
  title,
  subtitle,
  confirmActions,
  closeAction = { disabled: false, onClick: () => {} },
  secondaryAction,
  size = 'medium',
  children,
  resetDataAfterClose,
  testHook
}) => {
  const onClose = (_: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return;
    }

    closeAction.onClick();
  };

  return (
    // disableEscapeKeyDown will be removed after EH-4343
    <MuiModal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      disableEscapeKeyDown
      data-test-hook={testHook}
    >
      <Fade in={isOpen} onExited={resetDataAfterClose}>
        <StyledGrid container direction="column" size={size}>
          <StyledHeader>
            <Typography variant="h1">{title}</Typography>
            <Typography variant="body1">{subtitle}</Typography>
          </StyledHeader>

          <StyledBody>{children}</StyledBody>

          <StyledFooter container alignItems="center">
            {secondaryAction && (
              <ModalSecondaryAction action={secondaryAction} containerTestHook={testHook} />
            )}

            <OutlinedButton
              onClick={closeAction.onClick}
              size="medium"
              disabled={closeAction.disabled}
              testHook={closeAction.testHook}
              ml="auto"
            >
              Cancel
            </OutlinedButton>

            {confirmActions.map(
              ({ text, onClick, disabled, testHook, isVisible = true }, index) =>
                isVisible && (
                  <FilledButton
                    key={`${text}${index}`}
                    onClick={onClick}
                    disabled={disabled}
                    ml={16}
                    testHook={testHook}
                  >
                    {text}
                  </FilledButton>
                )
            )}
          </StyledFooter>
        </StyledGrid>
      </Fade>
    </MuiModal>
  );
};

const StyledGrid = styled(Grid)<{ size: 'small' | 'medium' | 'large' }>(
  ({ theme, size }) => css`
    position: fixed;
    inset: 0;
    height: fit-content;
    margin: ${theme.spacing(56, 'auto')};
    background-color: ${theme.palette.natural.white};
    padding: ${theme.spacing(24)};
    border: 1px solid ${theme.palette.natural.inactiveBackground};
    border-radius: ${theme.borderRadius.large};
    max-height: 90vh;
    ${size === 'small' && { width: '548px' }};
    ${size === 'medium' && { width: '780px' }};
    ${size === 'large' && { width: '1140px' }};
    flex-wrap: nowrap;

    &:focus {
      outline: 0;
    }
  `
);

const StyledHeader = styled(Box)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(28)};
  `
);

const StyledBody = styled(Box)`
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  &:empty {
    display: none;
  }
`;

const StyledFooter = styled(Grid)`
  flex: 1;
  flex-wrap: nowrap;
`;
