import { FC } from 'react';

import Select from 'react-select';

import AsyncSelect from 'react-select/async';

import { ValueContainer } from 'components/UIkit/atoms/Dropdown/Select/ReactSelectComponents';

import { StyledSelectContainer } from 'components/UIkit/atoms/Dropdown/Select/StyledSelectContainer';

import {
  useCommonReactSelectProps,
  getSelectContainerProps,
  AsyncBaseSelectProps,
  BaseSelectProps,
  ReactSelectInternalProps,
  SelectComponents
} from './Select.shared';
import { createAddOption, sortOptionsByLocale } from './SelectUtils';

// Base select component are for internal use only (e.i. by Autocomplete/Select components, never directly)
export const BaseSelect: FC<BaseSelectProps & ReactSelectInternalProps> = ({
  sortAlphabetically = true,
  value,
  ...props
}) => {
  let options =
    sortAlphabetically && props.options ? props.options.sort(sortOptionsByLocale) : props.options;

  if (props.addText) {
    options = [...options, createAddOption(props.addText)];
  }

  return (
    <StyledSelectContainer {...getSelectContainerProps(props)}>
      <Select {...useCommonReactSelectProps(props)} options={options} value={value} />
    </StyledSelectContainer>
  );
};

export const AsyncBaseSelect: FC<AsyncBaseSelectProps & ReactSelectInternalProps> = ({
  value,
  loadOptions,
  defaultOptions = false,
  displaySelectedSummary = false,
  ...props
}) => {
  const components: SelectComponents = props.components || {};

  if (displaySelectedSummary) {
    // show summary of selected items (ex: "3 Selected")
    components.ValueContainer = ValueContainer;
  }

  return (
    <StyledSelectContainer {...getSelectContainerProps(props)}>
      <AsyncSelect
        {...useCommonReactSelectProps({ ...props, components })}
        value={value}
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
      />
    </StyledSelectContainer>
  );
};
