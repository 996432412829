// @ts-strict-ignore
import { useMemo } from 'react';

import { AnalyticEventAction } from 'analytics';
import { trackFilterUsageAnalyticsEvent } from 'analytics/events/filter-usage';

import debounce from 'debounce-promise';

import { ActionMeta } from 'react-select';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import { TicketStatus } from 'models/Ticket';

import SearchBar from 'views/Dashboard/SearchBar';
import {
  EpisodeNumberFilterField,
  EpisodeOwnersFilterField,
  EpisodesFilterField,
  TaskRolesFilterField,
  TaskStatusFilterField,
  LastNameFirstLetterFilterField
} from 'views/Filters/FilterFields';
import { FilterName, SearchFiltersType, TasksFiltersType } from 'views/Filters/filters.types';
import { DEBOUNCE_DELAY } from 'views/Pages/CareManagement/CareManagement.hooks';
import { ValueOf } from 'views/Pages/CareManagement/CareManagementPage.utils';
import { ISelectOption } from 'views/Widgets/StyledSelect';

interface TasksFiltersProps {
  filters: TasksFiltersType;
  updateFiltersByKey: (value: string) => any;
  statusFilterOptions?: ISelectOption<TicketStatus>[]; // ability to override status field options
  patientId?: number;
  showEpisodeNumberFilter?: boolean;
  onDropdownFilterChange: (
    values: ValueOf<SearchFiltersType>,
    filterKey: keyof SearchFiltersType,
    filterName: FilterName,
    actionMeta: ActionMeta<any>
  ) => void;
}

export const TasksFilters = ({
  filters,
  updateFiltersByKey,
  statusFilterOptions,
  patientId,
  showEpisodeNumberFilter = false,
  onDropdownFilterChange
}: TasksFiltersProps) => {
  const { settingsStore } = useStores();
  const showSearchBar = settingsStore.hasFeature(FEATURES.SEARCH_BY_TASK_FILTER);
  const showEpisodesFilter = settingsStore.hasFeature(FEATURES.EPISODES_FILTER);
  const showTaskOwnerFilter = settingsStore.hasFeature(FEATURES.TASK_OWNER_FILTER);
  const showTaskRoleFilter = settingsStore.hasFeature(FEATURES.TASK_ROLE_FILTER);
  const showTaskStatusFilter = settingsStore.hasFeature(FEATURES.TASK_STATUS_FILTER);
  const showLastNameFirstLetterFilter = settingsStore.hasFeature(FEATURES.TASK_BY_LAST_NAME_FILTER);

  const onTrackSearchAnalyticsEvent = useMemo(() => {
    return debounce((searchQuery) => {
      trackFilterUsageAnalyticsEvent({
        action: !searchQuery ? AnalyticEventAction.Clear : AnalyticEventAction.SearchedByTask,
        value: FilterName.SearchByTask
      });
    }, DEBOUNCE_DELAY);
  }, []);

  return (
    <>
      {showSearchBar && (
        <SearchBar
          className="search"
          label="Search by Task"
          searchValue={filters.taskSearchTerm || ''}
          onSearchChanged={(value) => {
            onTrackSearchAnalyticsEvent(value);
            updateFiltersByKey('taskSearchTerm')(value);
          }}
          testHook="search-by-task"
        />
      )}

      {showLastNameFirstLetterFilter && (
        <LastNameFirstLetterFilterField
          value={filters.lastNameFirstLetter}
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(
              values,
              'lastNameFirstLetter',
              FilterName.LastNameFirstLetter,
              actionMeta
            )
          }
        />
      )}

      {showEpisodesFilter && (
        <EpisodesFilterField
          value={filters.episodeIds}
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'episodeIds', FilterName.Episodes, actionMeta)
          }
        />
      )}

      {showEpisodeNumberFilter && (
        <EpisodeNumberFilterField
          patientId={patientId}
          value={filters.episodeNumbers}
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'episodeNumbers', FilterName.EpisodeNumber, actionMeta)
          }
        />
      )}

      {showTaskOwnerFilter && (
        <EpisodeOwnersFilterField
          value={filters.owners}
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'owners', FilterName.TaskOwner, actionMeta)
          }
        />
      )}

      {showTaskRoleFilter && (
        <TaskRolesFilterField
          value={filters.role}
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'role', FilterName.TaskRole, actionMeta)
          }
        />
      )}

      {showTaskStatusFilter && (
        <TaskStatusFilterField
          options={statusFilterOptions}
          value={filters.status}
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'status', FilterName.TaskStatus, actionMeta)
          }
        />
      )}
    </>
  );
};
