import { FC, ReactNode } from 'react';

import { Box } from '@mui/material';
import { css, styled } from '@mui/material/styles';

import { Testable } from 'utils/TypeUtils';

import { HEADER_HEIGHT } from 'containers/Layout/Layout.constants';

import { WQ_SIDEBAR_WIDTH } from 'views/WorkQueue/WorkQueue.constants';

interface Props extends Testable {
  filtersComponent: ReactNode;
  children: ReactNode;
}

export const WorkQueueViewLayout: FC<Props> = ({ filtersComponent, children, testHook }) => (
  <>
    <StyledFiltersContainer>{filtersComponent}</StyledFiltersContainer>

    <StyledContentLayout data-test-hook={testHook}>
      <StyledContentContainer>{children}</StyledContentContainer>
    </StyledContentLayout>
  </>
);

const StyledFiltersContainer = styled(Box)(
  ({ theme }) => css`
    width: ${WQ_SIDEBAR_WIDTH}px;
    max-width: 240px;
    padding: ${theme.spacing(20)};
    border: 1px solid rgba(224, 226, 228, 1);
    overflow-y: auto;
    height: calc(100vh - ${HEADER_HEIGHT}px);
    position: fixed;
    top: ${theme.spacing(HEADER_HEIGHT)};
    left: 0;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(20)};

    .styled-select .styled-select__multi-value__label {
      // keep muli-select filters in a single line. see https://expain.atlassian.net/browse/EH-3722
      max-width: 118px;
    }
  `
);

const StyledContentLayout = styled(Box)(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    padding-left: calc(${WQ_SIDEBAR_WIDTH}px + 40px); //sidebar + 40px padding
    padding-right: ${theme.spacing(40)};
    padding-top: ${theme.spacing(48)};
  `
);

const StyledContentContainer = styled(Box)`
  flex: 1;
  max-width: 1250px;
`;
