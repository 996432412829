// @ts-strict-ignore
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { trackPreselectedFilterUsageAnalyticsEvent } from 'analytics/events/filter-usage';

import debounce from 'debounce-promise';
import { castArray, isEqual, omit, mapValues, pickBy, isEmpty, isNumber } from 'lodash/fp';
import { isObservableObject, toJS } from 'mobx';
import { useMount, useUnmount, useUpdateEffect } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import { getFiltersFromLocalStorageByKey } from 'utils/localStorage.utils';

import {
  DefaultTasksRequestParams,
  PatientFilters,
  SearchFiltersType,
  TasksFiltersType,
  TicketsFiltersType,
  TicketsQueryRequestParams
} from 'views/Filters/filters.types';

import { allFiltersToNames } from 'views/Filters/TicketFilters';

interface UseFiltersConfig<T> {
  excludeFieldKeys?: (keyof T)[];
  localStorageKey: string;
}

type FilterChangeHandler<T> = (filters: T) => void;

/* Getting filters from local storage by key*/
export const useGetPersistentFilters = (localStorageKey: string) =>
  useMemo(() => getFiltersFromLocalStorageByKey(localStorageKey), [localStorageKey]);

const hasValue = (value: any) => isNumber(value) || !isEmpty(value);

/* When needing to fire on change on mount */
export const usePersistentFiltersChangeMount = <T extends PatientFilters>(
  filterValues: T,
  onChange: (filters: T) => void,
  localStorageKey: string
) => {
  const initialFilterValues = useRef(filterValues);
  const localStorageFilters = useGetPersistentFilters(localStorageKey);

  useEffect(
    function updateFiltersFromLocalStorage() {
      if (localStorageFilters && !isEqual(initialFilterValues.current, localStorageFilters)) {
        trackPreselectedFilterUsageAnalyticsEvent(localStorageFilters, allFiltersToNames);
        onChange(localStorageFilters);
      }
    },
    [onChange, localStorageKey, localStorageFilters]
  ); // This needs to run once on mount
};

const extractFilterValue = (value: any): any => {
  if (Array.isArray(value)) {
    return value.length ? value.map((v) => extractFilterValue(v)).sort() : undefined;
  }
  if (value === undefined || value === null) {
    return undefined;
  }
  if (isObservableObject(value)) {
    return extractFilterValue(toJS(value));
  }
  if (value.value) {
    return extractFilterValue(value.value);
  }
  if (value.id) {
    return value.id;
  }
  return value;
};

/*
  set to local storage with callback functionality
*/
export const useSetPersistFilters = <T>(
  filterValues: T,
  onChange: FilterChangeHandler<T> | FilterChangeHandler<T>[],
  config: UseFiltersConfig<T>
) => {
  const { localStorageKey, excludeFieldKeys } = config;
  const callbacks = useMemo(() => castArray(onChange), [onChange]);

  const compareFilters = useCallback(function areFiltersEqual(
    a: T,
    b: T,
    excludeKeys: (keyof T)[]
  ) {
    const comperableA = mapValues(extractFilterValue, pickBy(hasValue, omit(excludeKeys, a as {})));
    const comperableB = mapValues(extractFilterValue, pickBy(hasValue, omit(excludeKeys, b as {})));
    return isEqual(comperableA, comperableB);
  },
  []);

  const compareWithCurrentFilters = useCallback(
    function compareWithCurrent(filters: T) {
      return compareFilters(filterValues, filters, excludeFieldKeys);
    },
    [filterValues, compareFilters, excludeFieldKeys]
  );

  const updateAllFilters = useCallback(
    function updateFilters(filters: T, onlyIfChanged = true) {
      if (!onlyIfChanged || !compareWithCurrentFilters(filters)) {
        const filtersString = JSON.stringify(omit(excludeFieldKeys as string[], filters as {}));
        localStorage.setItem(localStorageKey, filtersString);
        callbacks.forEach((callback) => callback(filters));
      }
    },
    [callbacks, compareWithCurrentFilters, excludeFieldKeys, localStorageKey]
  );

  const updateFiltersByKey = useCallback(
    function <K extends keyof T>(key: K) {
      return (value: any) => {
        const updatedFilters = { ...filterValues, [key]: value };
        try {
          const filtersString = JSON.stringify(omit(excludeFieldKeys, updatedFilters));
          localStorage.setItem(localStorageKey, filtersString);
        } catch (e) {
          console.warn(
            `was not able to store filter values in localstorage - key - ${localStorageKey} - `
          );
        }
        callbacks.forEach((callback) => callback(updatedFilters));
      };
    },
    [callbacks, excludeFieldKeys, filterValues, localStorageKey]
  );

  const hasAnyActiveFilter = useMemo(() => {
    return Object.values(omit(['fromDate', 'toDate'], filterValues as {})).some((value) =>
      hasValue(extractFilterValue(value))
    );
  }, [filterValues]);

  return {
    updateFiltersByKey,
    updateAllFilters,
    compareFilters,
    compareWithCurrentFilters,
    hasAnyActiveFilter
  };
};

/* update store on mount*/
export const usePersistentFiltersStoreMount = (localStorageKey: string) => {
  const { ticketFiltersStore } = useStores();
  const parsedFilters: TicketsFiltersType = useGetPersistentFilters(localStorageKey);

  useEffect(() => {
    if (parsedFilters) {
      ticketFiltersStore.updateFilters(parsedFilters);
      trackPreselectedFilterUsageAnalyticsEvent(parsedFilters, allFiltersToNames);
    }
    return () => ticketFiltersStore.resetFiltersAndDefaultQueryValues();
  }, [localStorageKey, parsedFilters, ticketFiltersStore]);
};

/* Tasks only (base)*/
export const useTaskFilters = (
  defaultQuery: DefaultTasksRequestParams,
  intervalTime?: number,
  patientId?: number
) => {
  const { tasksStore, ticketFiltersStore, ticketsStore } = useStores();

  useMount(function startFetchingTasks() {
    ticketFiltersStore.setDefaultTaskQuery(defaultQuery);
    tasksStore.fetchTasksOrDelta(intervalTime);
  });

  useUpdateEffect(
    function onPatientChanged() {
      ticketFiltersStore.setDefaultTaskQuery(defaultQuery);
      tasksStore.fetchTasksOrDelta(intervalTime);
    },
    [patientId]
  );

  useUnmount(function resetStore() {
    tasksStore.resetStore();
  });

  const debouncedFetch = useMemo(() => {
    return debounce(async () => {
      ticketFiltersStore.setUpdatingFilters(true);
      await tasksStore.fetchTasks();
      ticketFiltersStore.setUpdatingFilters(false);
    }, 400);
  }, [tasksStore, ticketFiltersStore]);

  const handleFiltersChanged = useCallback(
    (filters: TicketsFiltersType) => {
      tasksStore.clearTimestamp();
      ticketFiltersStore.updateFilters(filters);
      debouncedFetch();

      if (ticketsStore.ticketsBulkActionSet.size > 0) {
        ticketsStore.resetTicketsBulkActionSet();
      }
    },
    [ticketFiltersStore, tasksStore, ticketsStore, debouncedFetch]
  );

  return handleFiltersChanged;
};

/* Tasks only full flow */
export const useTasksServerPersistentFilters = (
  defaultQuery: DefaultTasksRequestParams,
  localStorageKey: string
) => {
  usePersistentFiltersStoreMount(localStorageKey);
  const { ticketFiltersStore } = useStores();
  const handleTasksChanged = useTaskFilters(defaultQuery);

  const { updateFiltersByKey } = useSetPersistFilters<TasksFiltersType>(
    ticketFiltersStore.filters,
    handleTasksChanged,
    {
      localStorageKey,
      excludeFieldKeys: ['taskSearchTerm', 'searchTerm', 'fromDate', 'toDate']
    }
  );

  return { filters: ticketFiltersStore.filters, updateFiltersByKey };
};

export const useClientPersistentFilters = function <T extends PatientFilters>(
  filterValues: T,
  onChange: (filters: T) => void,
  config: UseFiltersConfig<T>
) {
  const defaults: Partial<UseFiltersConfig<T>> = {
    excludeFieldKeys: ['searchTerm']
  };
  const { localStorageKey, excludeFieldKeys } = { ...defaults, ...config };

  usePersistentFiltersChangeMount(filterValues, onChange, localStorageKey);

  const { updateFiltersByKey } = useSetPersistFilters(filterValues, onChange, {
    localStorageKey,
    excludeFieldKeys
  });

  return updateFiltersByKey;
};

export const useSinglePatientMount = (config: SinglePatientPeriodicConfig) => {
  const { patientId, defaultTicketsQuery, localStorageKey } = config;
  const { ticketFiltersStore, ticketsStore } = useStores();

  usePersistentFiltersStoreMount(localStorageKey);
  useEffect(
    function startFetchingPatientDataAndTickets() {
      ticketFiltersStore.setDefaultTicketQuery(defaultTicketsQuery);
      ticketsStore.fetchTicketsForSinglePatientPeriodically(patientId);

      return () => ticketsStore.stopSinglePatientRefresh();
    },
    [ticketsStore, ticketFiltersStore, patientId, defaultTicketsQuery]
  );
};

interface SinglePatientPeriodicConfig {
  patientId: number;
  defaultTicketsQuery: TicketsQueryRequestParams;
  localStorageKey: string;
}
/* Periodic fetch for single patient - patient data and tickets */
const useSinglePatientTicketFilters = (patientId: number) => {
  const { ticketsStore, ticketFiltersStore } = useStores();

  const debouncedFetch = useMemo(() => {
    return debounce(async () => {
      ticketFiltersStore.setUpdatingFilters(true);
      await ticketsStore.fetchTicketsForPatient(patientId);
      ticketFiltersStore.setUpdatingFilters(false);
    }, 400);
  }, [ticketFiltersStore, ticketsStore, patientId]);

  const handleFiltersChanged = useCallback(
    (filters: TicketsFiltersType) => {
      ticketFiltersStore.updateFilters(filters);
      debouncedFetch();

      if (ticketsStore.ticketsBulkActionSet.size > 0) {
        ticketsStore.resetTicketsBulkActionSet();
      }
    },
    [debouncedFetch, ticketFiltersStore, ticketsStore]
  );

  return handleFiltersChanged;
};

export const useSinglePatientPeriodicUpdate = (
  patientId: number,
  defaultTasksQuery: DefaultTasksRequestParams,
  defaultTicketsQuery: TicketsQueryRequestParams,
  localStorageKey: string
) => {
  useSinglePatientMount({ patientId, defaultTicketsQuery, localStorageKey });
  const { ticketFiltersStore, settingsStore } = useStores();
  const {
    institutionSettings: { singlePatientRetrievalInterval }
  } = settingsStore;
  const handleTasksChanged = useTaskFilters(
    defaultTasksQuery,
    singlePatientRetrievalInterval,
    patientId
  );
  const handleTicketsChanged = useSinglePatientTicketFilters(patientId);

  const { updateFiltersByKey } = useSetPersistFilters<SearchFiltersType>(
    ticketFiltersStore.filters,
    [handleTasksChanged, handleTicketsChanged],
    {
      localStorageKey,
      excludeFieldKeys: ['taskSearchTerm', 'searchTerm', 'fromDate', 'toDate']
    }
  );

  return { filters: ticketFiltersStore.filters, updateFiltersByKey };
};
