export const TASK_QUERY_FROM_DATE_KEY = 'TASK_QUERY_FROM_DATE_KEY';
export const TASK_QUERY_TO_DATE_KEY = 'TASK_QUERY_TO_DATE_KEY';
export const WORKQUEUE_FILTERS_LOCAL_STORAGE_KEY = '_triagePageFilters';
export const WORK_QUEUE_RESOLVED_ITEMS_FILTERS_LOCAL_STORAGE_KEY = 'WQ_RESOLVED_ITEMS_FILTERS';
export const RESOLVED_DATE_FILTER_TODAY_OPTION_ID = 1;
export const RESOLVED_DATE_FILTER_YESTERDAY_OPTION_ID = 2;
export const RESOLVED_DATE_FILTER_THIS_WEEK_OPTION_ID = 3;
export const RESOLVED_DATE_FILTER_LAST_WEEK_OPTION_ID = 4;
export const RESOLVED_DATE_FILTER_ALL_TIME_OPTION_ID = 5;
export const defaultResolvedDateFilterOption = {
  value: RESOLVED_DATE_FILTER_TODAY_OPTION_ID,
  label: 'Today'
};
export type ResolvedDateFilterOptionId =
  | typeof RESOLVED_DATE_FILTER_TODAY_OPTION_ID
  | typeof RESOLVED_DATE_FILTER_YESTERDAY_OPTION_ID
  | typeof RESOLVED_DATE_FILTER_THIS_WEEK_OPTION_ID
  | typeof RESOLVED_DATE_FILTER_LAST_WEEK_OPTION_ID
  | typeof RESOLVED_DATE_FILTER_ALL_TIME_OPTION_ID;

export enum SPECIAL_FILTERS {
  UNASSIGNED = -1
}

export const UNASSIGNED_TICKETS_OPTION = {
  value: SPECIAL_FILTERS.UNASSIGNED,
  label: 'Unassigned Tickets'
};
