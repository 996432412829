import { transformErrorUiProps, ErrorName, HttpError } from 'errors';

import HttpService from 'services/HttpService';

import { API_URLS } from 'constants/apiUrls';

import { FilterSetMetadata, FilterSetServerData } from 'views/Filters/filters.types';

const httpService = new HttpService('filter-sets', true);

const transformFilterSetError = (title: string, description?: string) => {
  return (error: HttpError) => {
    error.name = ErrorName.FilterSetActionFailed;
    return transformErrorUiProps(title, description)(error);
  };
};

class FilterSetsFetcher {
  static async fetchAllFilterSets() {
    return await httpService.get<FilterSetMetadata[]>({
      url: API_URLS.FILTER_SETS,
      transformError: transformFilterSetError('Cannot Load Filter Sets')
    });
  }

  static async fetchFilterSet(id: number) {
    return await httpService.get<FilterSetServerData>({
      url: API_URLS.GET_FILTER_SET_DATA(id),
      transformError: transformFilterSetError('Cannot Load Filter Set')
    });
  }

  static async createFilterSet({ name, filters, isShared }: Omit<FilterSetServerData, 'id'>) {
    return await httpService.post<number>({
      url: API_URLS.FILTER_SETS,
      data: { name, isShared, filters },
      transformError: transformFilterSetError('Failed to Save Filter Set')
    });
  }

  static async updateFilterSet({ id, name, filters, isShared }: FilterSetServerData) {
    return await httpService.put<void>({
      url: API_URLS.UPDATE_FILTER_SET(id),
      data: { name, isShared, filters },
      transformError: transformFilterSetError('Failed to Update Filter Set')
    });
  }

  static async deleteFilterSet(id: number): Promise<void> {
    return await httpService.delete({
      url: API_URLS.DELETE_FILTER_SET(id),
      transformError: transformFilterSetError('Cannot Delete Filter Set')
    });
  }
}

export default FilterSetsFetcher;
