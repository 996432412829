import { FC, ReactNode } from 'react';

import { Box, css, styled, Typography } from '@mui/material';

import classNames from 'classnames';

import { Testable } from 'utils/TypeUtils';

import Icon from 'components/Icons/Icon';

import { Tooltip } from 'components/Tooltip/Tooltip';
import { SelectVariant } from 'components/UIkit/atoms/Dropdown/Select.shared';
import { Text } from 'components/UIkit/atoms/Text';
interface Props extends Testable {
  children?: ReactNode;
  label?: string;
  labelTooltip?: string;
  isError?: any;
  errorMessage?: string;
  variant?: SelectVariant;
}

export const StyledSelectText = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isError'
})<{ isError: boolean }>(
  ({ theme, ...props }) => css`
    span {
      color: ${props.isError ? theme.palette.error.dark : theme.palette.text.secondary};
    }
  `
);

const SelectHeader: FC<Props> = ({
  isError,
  testHook,
  label,
  labelTooltip,
  variant = 'primary'
}) => (
  <Box display="flex" width="fit-content" className="styled-select__label">
    <StyledSelectText id={testHook} isError={isError}>
      <Text variant={variant === 'secondary' ? 'subtext' : 'body1'}>{label}</Text>
    </StyledSelectText>

    {labelTooltip && (
      <Tooltip
        label={
          <Box ml={2}>
            {/*Ask Yuvi*/}
            <Icon.Info />
          </Box>
        }
      >
        <Box p={4}>
          {/*Ask Yuvi*/}
          {labelTooltip}
        </Box>
      </Tooltip>
    )}
  </Box>
);

const SelectFooter: FC<Props> = ({ isError, errorMessage }) => (
  <StyledSelectText isError={isError}>
    <Typography variant="helper-text">{errorMessage}</Typography>
  </StyledSelectText>
);

// TODO: Convert to styled API
export const StyledSelectContainer: FC<Props> = ({ children, ...props }) => {
  return (
    <div
      className={classNames('styled-select', {
        error: props.isError,
        secondary: props.variant === 'secondary'
      })}
      // This e.stopPropagation is to prevent bug that can happen in in PatientActivation page - because each row is clickable
      // When selecting an option in react-select it triggers the click event (in Protocol modal)
      onClick={(e) => e.stopPropagation()}
    >
      {props.label && <SelectHeader {...props} />}
      <div className="selection-area-container">{children}</div>
      {props.errorMessage && <SelectFooter {...props} />}
    </div>
  );
};
