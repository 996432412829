// @ts-strict-ignore
import {
  ComponentType,
  FocusEventHandler,
  forwardRef,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState
} from 'react';

import { isEmpty } from 'lodash/fp';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { InputActionMeta, SelectComponentsConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';

import { ActionMeta } from 'react-select/dist/declarations/src/types';
import { v4 as uuid } from 'uuid';

import { Testable } from 'utils/TypeUtils';

// temp import (when refactor is completed current file should be remove)
import { StyledSelectContainer } from 'components/UIkit/atoms/Dropdown/Select/StyledSelectContainer';
import { reactSelectStyle } from 'components/UIkit/atoms/Dropdown/Select.styles';

import { useSelectRHFRegister } from 'components/UIkit/atoms/Dropdown/SelectUtils';

import './StyledSelect.scss';

const CLASS_NAME_PREFIX = 'styled-select';

export interface ISelectOption<V> {
  value?: V;
  label: string;
  isDisabled?: boolean;
  options?: any[]; // for grouping
}

export type IStyledSelectComponents = SelectComponentsConfig<any, boolean, any>;

export interface IStyledSelectProps extends Testable {
  value: any;
  label?: string;
  options: Array<ISelectOption<any>>;
  error?: boolean;
  placeholder?: string;
  errorMessage?: string;
  isRequired?: boolean | string;
  onChange?: (value: any, actionMeta: ActionMeta<ISelectOption<any>>) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
  components?: IStyledSelectComponents;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  inputValue?: string;
  onClick?: () => void;
  multi?: boolean;
  disable?: boolean;
  autoFocus?: boolean;
  clearable?: boolean;
  getOptionValue?: (option: ISelectOption<any>) => string;
  getOptionLabel?: (option: ISelectOption<any>) => ReactNode;
  filterOption?: (option: any, searchText: string) => boolean;
  sortAlphabetically?: boolean;
  labelTooltip?: string;
  className?: string;
  backspaceRemovesValue?: boolean;
}

export const sortOptionsByLocale = (a: ISelectOption<any>, b: ISelectOption<any>) =>
  a.label.localeCompare(b.label);

export interface SelectFieldCustomComponents {
  Option?: ComponentType<any>;
  MultiValueLabel?: ComponentType<any>;
  MultiValueRemove?: ComponentType<any>;
  ValueContainer?: ComponentType<any>;
  SingleValue?: ComponentType<any>;
  Placeholder?: ComponentType<any>;
}

const tagInputComponents: SelectComponentsConfig<any, boolean, any> = {
  DropdownIndicator: null
};

const createOption = (label: string) => ({
  label,
  value: label,
  id: uuid()
});

const initializer = (option: ISelectOption<any>) => ({
  ...option,
  id: uuid()
});

interface StyledTagInputProps<T> extends Partial<IStyledSelectProps> {
  getOptionLabel?: (option: ISelectOption<any>) => string;
  formatOptionLabel?: (option: T, labelMeta: FormatOptionLabelMeta<T>) => ReactNode;
  isDisabled?: boolean;
}

export const StyledTagInput = forwardRef<any, StyledTagInputProps<any>>((props, ref) => {
  const {
    onChange,
    placeholder = 'Use Enter to save items...',
    value: defaultValue,
    clearable: isClearable,
    backspaceRemovesValue,
    components,
    getOptionLabel,
    className,
    formatOptionLabel,
    label,
    isDisabled
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(defaultValue?.map(initializer) || []);
  const mergedComponents = {
    ...tagInputComponents,
    ...components
  };

  useEffect(
    function onValuePropChanged() {
      setValue(defaultValue?.map(initializer) || []);
    },
    [defaultValue]
  );

  const handleChange = (newValue: any, action: ActionMeta<ISelectOption<any>>) => {
    if (!newValue && isEmpty(value)) {
      return;
    }
    setValue(newValue);
    onChange(newValue, action);
  };

  const commitValues = useCallback(() => {
    const valueToCommit = [...(value || []), createOption(inputValue)];
    setValue(valueToCommit);
    onChange(valueToCommit, null);
  }, [inputValue, onChange, value]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if (!inputValue) {
        return;
      }
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          setInputValue('');
          commitValues();
          event.preventDefault();
      }
    },
    [commitValues, inputValue]
  );

  const handleBlur: FocusEventHandler = useCallback(() => {
    if (isEmpty(inputValue)) {
      return;
    }

    commitValues();
  }, [commitValues, inputValue]);

  return (
    <StyledSelectContainer {...props}>
      <CreatableSelect
        classNamePrefix={CLASS_NAME_PREFIX}
        className={className}
        components={mergedComponents}
        inputValue={inputValue}
        isClearable={isClearable}
        backspaceRemovesValue={backspaceRemovesValue}
        onBlur={handleBlur}
        isMulti
        getOptionValue={(opt) => opt.id}
        formatOptionLabel={formatOptionLabel}
        menuIsOpen={false}
        ref={ref}
        onChange={handleChange}
        onInputChange={setInputValue}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={value}
        getOptionLabel={getOptionLabel}
        label={label}
        styles={reactSelectStyle}
        isDisabled={isDisabled}
      />
    </StyledSelectContainer>
  );
});

interface RHFTagsInputProps {
  label?: string;
  isError?: boolean;
  placeholder?: string | null;
  errorMessage?: string;
  isRequired?: boolean | string;
  register: UseFormRegister<any>;
  control: Control<any>;
  validate?: (value: any) => boolean | string;
  name: string;
  defaultValue?: any;
  getOptionLabel?: (option: object) => string;
  getOptionValue?: (option: object) => any;
  isClearable?: boolean;
  backspaceRemovesValue?: boolean;
  components?: SelectComponentsConfig<any, boolean, any>;
  isDisabled?: boolean;
}

export const RHFTagsInput = (props: RHFTagsInputProps) => {
  const {
    placeholder,
    register,
    control,
    validate,
    isRequired,
    name,
    isClearable,
    backspaceRemovesValue,
    components,
    label,
    isDisabled
  } = props;
  useSelectRHFRegister(register, validate, isRequired, name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <StyledTagInput
          value={field.value}
          onChange={field.onChange}
          placeholder={placeholder}
          clearable={isClearable}
          backspaceRemovesValue={backspaceRemovesValue}
          components={components}
          label={label}
          isDisabled={isDisabled}
        />
      )}
    />
  );
};
