import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';

import { AnalyticEventAction } from 'analytics';
import { trackScrollAnalyticsEvent } from 'analytics/events/scroll';
import { trackTabNavigationAnalyticsEvent } from 'analytics/events/tab-navigation';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { useMount, useUnmount } from 'react-use';

import { workQueueTestSelectors } from 'tests/models/pages/work-queue/work-queue-page.selectors';

import { useStores } from 'mobx/hooks/useStores';

import AuditService, { AuditAction } from 'fetchers/AuditFetcher';

import { API_LABELS } from 'constants/apiUrls';

import Patient from 'models/Patient';
import Ticket from 'models/Ticket';

import { FOOTER_HEIGHT, HEADER_HEIGHT } from 'containers/Layout/Layout.constants';

import DoctorDetails from 'views/Dashboard/DoctorDetails';

import { WorkQueueOpenItemsFilters } from 'views/Filters/WorkQueueOpenItemsFilters';

import ConnectTicketsToCallEditModal from 'views/Modals/ConnectTicketsToCallEditModal';

import {
  workQueueTabToNameMap,
  workQueueToggleButtonOptions,
  WQ_EXPAND_KEY
} from 'views/WorkQueue/WorkQueue.constants';
import 'views/WorkQueue/WorkQueue.scss';

import { WorkQueueTab, WorkQueueTabName } from 'views/WorkQueue/WorkQueue.types';

import {
  WorkQueueOpenItemsSectionName,
  WorkQueueSectionName
} from 'views/WorkQueue/WorkQueue.types';
import { getSectionToFetchByType } from 'views/WorkQueue/WorkQueue.utils';

import WorkQueueSectionsComponent from 'views/WorkQueue/WorkQueueOpenItemsView/WorkQueueSectionsComponent';

import { WorkQueueViewLayout } from 'views/WorkQueue/WorkQueueViewLayout';

import RefreshButton from 'components/Buttons/RefreshDataButton';
import FixedLoader from 'components/Loaders/FixedLoader';
import TicketOverviewProvider, {
  ActionCallbacks
} from 'components/Ticket/TicketsContainers/TicketOverviewProvider';
import { ExclusiveToggleButton } from 'components/UIkit/atoms/Button';

export const WorkQueueOpenItemsView: FC = observer(() => {
  const [isFetchingWq, setIsFetchingWq] = useState(false);
  const [isManualDataRefresh, setIsManualDataRefresh] = useState(false);
  const { userStore, ticketFiltersStore, callsStore, workQueueStore } = useStores();
  const history = useHistory();

  useMount(function init() {
    initWQ();
  });

  useUnmount(function reset() {
    workQueueStore.resetStore();
  });

  const initWQ = async () => {
    try {
      setIsFetchingWq(true);
      await workQueueStore.init();
    } catch (error) {
      throw error;
    } finally {
      setIsFetchingWq(false);
    }
  };

  useEffect(function addScrollEventListener() {
    const appElement = document.getElementById('app')!; //this solution is just for the scrollable div with the id="app" in Layout component
    appElement?.addEventListener('scroll', handleScroll);

    return () => {
      //remove the event listener when Work Queue component unmount
      appElement?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const lastAnalyticsEventScrollY = useRef(0);
  const prevScrollY = useRef(0);

  const handleScroll = (event: any) => {
    const analyticsEventTriggerScrollPoint =
      event.currentTarget.offsetHeight - HEADER_HEIGHT - FOOTER_HEIGHT;
    const currentScrollY = event.currentTarget.scrollTop;

    const scrollDistance = Math.abs(lastAnalyticsEventScrollY.current - currentScrollY);

    if (scrollDistance > analyticsEventTriggerScrollPoint) {
      trackScrollAnalyticsEvent({
        action:
          currentScrollY > lastAnalyticsEventScrollY.current
            ? AnalyticEventAction.Down
            : AnalyticEventAction.Up,
        value: currentScrollY
      });

      lastAnalyticsEventScrollY.current = currentScrollY;
    }

    prevScrollY.current = currentScrollY;
  };

  const isLoading = isManualDataRefresh || ticketFiltersStore.updatingFilters;

  const onTicketAssign = useCallback(
    (ticket: Ticket, doctorId: number | null) => {
      const sectionsToFetch: WorkQueueOpenItemsSectionName[] = [];
      const additionalSectionByType = getSectionToFetchByType(
        ticket,
        workQueueStore.patientsMap.get(ticket.patientId)
      );

      const isAssigningToMe = doctorId === userStore.currentClinicianId;
      const isCurrentlyAssignedToMe = ticket.mainAssign?.doctor.id === userStore.currentClinicianId;
      const isUnassignFromMe =
        ticket.mainAssign?.doctor.id === userStore.currentClinicianId && !doctorId;

      if (isAssigningToMe || isUnassignFromMe || isCurrentlyAssignedToMe) {
        sectionsToFetch.push(WorkQueueSectionName.AssignedToMe);
      }

      if (additionalSectionByType) {
        sectionsToFetch.push(additionalSectionByType);
      }

      workQueueStore.fetchSections(sectionsToFetch, {
        networkLabel: API_LABELS.WQ_FETCH_SECTIONS_DATA_AFTER_TICKET_ACTION(ticket.id)
      });
    },
    [userStore.currentClinicianId, workQueueStore]
  );

  const onResolve = useCallback(
    (ticketIds: number[]) => {
      const isInAssignedToMe = workQueueStore.isInAssignedToMe(ticketIds);
      // single resolve can be done from assigned to me or tasks
      if (isInAssignedToMe) {
        workQueueStore.fetchSections(WorkQueueSectionName.AssignedToMe, {
          networkLabel: API_LABELS.WQ_FETCH_SECTIONS_DATA_AFTER_TICKET_ACTION(ticketIds[0])
        });
      } else {
        workQueueStore.fetchSections(WorkQueueSectionName.TasksDue, {
          networkLabel: API_LABELS.WQ_FETCH_SECTIONS_DATA_AFTER_TICKET_ACTION(ticketIds[0])
        });
      }
    },
    [workQueueStore]
  );

  const onEditTicket = useCallback(
    (ticket: Ticket) => {
      // Edit is available on operator tickets only and only for assigned tickets.
      // We fetch all operator ticket sections + assign to me section (we can change assignee in edit)
      workQueueStore.fetchSections(
        [
          WorkQueueSectionName.TicketsAndCallbackRequests,
          WorkQueueSectionName.AssignedToMe,
          WorkQueueSectionName.UrgentPatientReports
        ],
        { networkLabel: API_LABELS.WQ_FETCH_SECTIONS_DATA_AFTER_TICKET_ACTION(ticket.id) }
      );
    },
    [workQueueStore]
  );

  const onDeleteTicket = useCallback(
    (ticket: Ticket) => {
      // Delete is available on operator tickets only and only for assigned tickets.
      workQueueStore.fetchSections(WorkQueueSectionName.AssignedToMe, {
        networkLabel: API_LABELS.WQ_FETCH_SECTIONS_DATA_AFTER_TICKET_ACTION(ticket.id)
      });
    },
    [workQueueStore]
  );

  const onEditTask = useCallback(
    (task: Ticket) => {
      // Assignees (or other filterable properties) could have changed.
      // We need to fetch assigned to me and tasks again.
      workQueueStore.fetchSections(
        [WorkQueueSectionName.AssignedToMe, WorkQueueSectionName.TasksDue],
        {
          networkLabel: API_LABELS.WQ_FETCH_SECTIONS_DATA_AFTER_TICKET_ACTION(task.id)
        }
      );
    },
    [workQueueStore]
  );

  const commonTaskActionHandler = useCallback(
    (task: Ticket) => {
      const networkLabel = {
        networkLabel: API_LABELS.WQ_FETCH_SECTIONS_DATA_AFTER_TICKET_ACTION(task.id)
      };

      if (task.mainAssign?.doctor.id === userStore.currentClinicianId) {
        workQueueStore.fetchSections(WorkQueueSectionName.AssignedToMe, networkLabel);
      } else {
        workQueueStore.fetchSections(WorkQueueSectionName.TasksDue, networkLabel);
      }
    },
    [userStore.currentClinicianId, workQueueStore]
  );

  const onOverdueAction = useCallback(
    (patient: Patient) => {
      workQueueStore.fetchSections(WorkQueueSectionName.OverdueReports, {
        networkLabel: API_LABELS.WQ_FETCH_OVERDUE_SECTION(patient.id)
      });
    },
    [workQueueStore]
  );

  const onCallSave = useCallback(() => {
    // a call save can happen in WQ by opening the edit connected tickets modal.
    // This could potentially affect any ticket section
    workQueueStore.fetchSections([
      WorkQueueSectionName.AssignedToMe,
      WorkQueueSectionName.TicketsAndCallbackRequests,
      WorkQueueSectionName.UrgentPatientReports,
      WorkQueueSectionName.OtherPatientReports
    ]);
  }, [workQueueStore]);

  const actionCallbacks: ActionCallbacks = {
    // general
    onAssign: onTicketAssign,
    onResolve: onResolve,
    // tickets
    onEditTicket: onEditTicket,
    onDeleteTicket: onDeleteTicket,
    // tasks
    onEditTask: onEditTask,
    onDeleteTask: commonTaskActionHandler,
    onRescheduleTask: commonTaskActionHandler,
    onStatusChange: commonTaskActionHandler,
    // overdue reports
    onRequestReport: onOverdueAction,
    onSnooze: onOverdueAction
  };

  const refreshButtonLastUpdatedTime = workQueueStore.autoRefreshLastRetrievedTimestamp
    ? new Date(workQueueStore.autoRefreshLastRetrievedTimestamp as number)
    : null;

  const handleTabClicked = (_event: MouseEvent<HTMLElement>, newSelection: string | number) => {
    trackTabNavigationAnalyticsEvent({
      action: AnalyticEventAction.TabSelect,
      value: workQueueTabToNameMap[newSelection as WorkQueueTab],
      tab: workQueueTabToNameMap[WorkQueueTab.OpenItems]
    });

    if (newSelection === WorkQueueTab.OpenItems) {
      return;
    }

    history.push('/work-queue/resolved-items');
  };

  const handleRefreshClicked = async () => {
    AuditService.addAudit({
      action: AuditAction.REFRESH_DATA_BUTTON_CLICKED,
      actionDetails: { page: 'work_queue' }
    });
    setIsManualDataRefresh(true);

    try {
      await workQueueStore.fetchAndCheckSections('refresh-wq');
    } catch (error) {
      throw error;
    } finally {
      setIsManualDataRefresh(false);
    }
  };

  return (
    <WorkQueueViewLayout
      filtersComponent={<WorkQueueOpenItemsFilters />}
      testHook={workQueueTestSelectors.openItemsTab.container}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={40}>
        <DoctorDetails doctor={userStore.currentDoctor} showDepartments={false} />

        <Box display="flex" alignItems="center" gap={20}>
          <ExclusiveToggleButton
            options={workQueueToggleButtonOptions}
            value={WorkQueueTab.OpenItems}
            onChange={handleTabClicked}
            variant="secondary"
          />

          <RefreshButton
            onClick={handleRefreshClicked}
            lastUpdatedTime={refreshButtonLastUpdatedTime}
            isLoading={isManualDataRefresh}
            testHook={workQueueTestSelectors.refreshDataButton}
            width="206px"
            hasBoxShadow={false}
            alignStart
          />
        </Box>
      </Box>

      <TicketOverviewProvider
        expandStatePersistKey={WQ_EXPAND_KEY}
        actionCallbacks={actionCallbacks}
        getPatientSymptomOperatorTickets={workQueueStore.getPatientSymptomOperatorTickets}
        useIntersectionObserver
        tab={WorkQueueTabName.OpenItems}
      >
        {callsStore.currentEditedCall && <ConnectTicketsToCallEditModal onSave={onCallSave} />}

        {isLoading && <FixedLoader hasBackdrop />}

        {isFetchingWq ? (
          <FixedLoader background={isFetchingWq} />
        ) : (
          <div className="report-sections">
            <WorkQueueSectionsComponent isLoading={isLoading} />
          </div>
        )}
      </TicketOverviewProvider>
    </WorkQueueViewLayout>
  );
});
