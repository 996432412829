import { useState, useMemo } from 'react';

import { Box, css, styled } from '@mui/material';
import {
  trackDropdownFilterUsageAnalyticsEvent,
  trackSearchByNameOrMrnFilterUsageAnalyticsEvent
} from 'analytics/events/filter-usage';
import debounce from 'debounce-promise';
import { observer } from 'mobx-react';

import { ActionMeta } from 'react-select';

import { useMount } from 'react-use';

import { useStores } from 'mobx/hooks/useStores';

import { FEATURES } from 'constants/features';

import { TICKET_ACTIVE_STATUS_OPTIONS } from 'models/Ticket';

import { useFilterSets, UseFilterSetsMethods } from 'hooks/useFilterSets';

import SearchBar from 'views/Dashboard/SearchBar';
import {
  CliniciansFilterField,
  EpisodeOwnersFilterField,
  EpisodesFilterField,
  LastNameFirstLetterFilterField,
  LocationsFilterField,
  ProvidersFilterField,
  RegionsFilterField,
  TagsFilterField,
  TaskRolesFilterField,
  TaskStatusFilterField,
  TicketTypeFilterField
} from 'views/Filters/FilterFields';
import { FilterName, SearchFiltersType } from 'views/Filters/filters.types';
import { useWqFilters } from 'views/Filters/useWqFilters';
import { DEBOUNCE_DELAY } from 'views/Pages/CareManagement/CareManagement.hooks';
import { ValueOf } from 'views/Pages/CareManagement/CareManagementPage.utils';
import { WorkQueueTabName } from 'views/WorkQueue/WorkQueue.types';

import { Text } from 'components/UIkit/atoms/Text';

import { FilterSetDialog, Props as FilterSetDialogProps } from './FilterSetDialog';
import { FilterSetDropdown } from './FilterSetDropdown';

export const WorkQueueOpenItemsFilters = observer(() => {
  const { settingsStore } = useStores();
  const useFilterSetsMethods: UseFilterSetsMethods = useFilterSets();
  const {
    filterSetsMetadata,
    activeFilterSetMetadata,
    chooseFilterSet,
    loadFilterSetById,
    initFilterSets,
    isDirty,
    isLoading
  } = useFilterSetsMethods;

  const { filters, updateFiltersByKey, hasAnyActiveFilter } = useWqFilters();

  const closeDialog = () => {
    setFilterSetDialogConfig(defaultDialogConfig);
  };

  const defaultDialogConfig: FilterSetDialogProps = {
    open: false,
    mode: 'create',
    name: '',
    isShared: false,
    selectedFilters: filters,
    onClose: closeDialog,
    useFilterSetsMethods
  };

  const [filterSetDialogConfig, setFilterSetDialogConfig] =
    useState<FilterSetDialogProps>(defaultDialogConfig);

  useMount(() => {
    initFilterSets();
  });

  const isTicketSearchFilterVisible = settingsStore.hasFeature(
    FEATURES.SEARCH_BY_NAME_OR_MRN_FILTER
  );

  const isPatientTagsFilterVisible = settingsStore.hasFeature(FEATURES.PATIENT_TAGS_FILTER);
  const isProvidersFilterVisible = settingsStore.hasFeature(FEATURES.PROVIDERS_FILTER);
  const isRegionsFilterVisible = settingsStore.hasFeature(FEATURES.REGIONS);
  const isLocationsFilterVisible =
    settingsStore.hasFeature(FEATURES.LOCATIONS_FILTER) && !isRegionsFilterVisible;
  const isAssigneesFilterVisible = settingsStore.hasFeature(FEATURES.ASSIGNEES_FILTER);
  const isTaskSearchFilterVisible = settingsStore.hasFeature(FEATURES.SEARCH_BY_TASK_FILTER);
  const isTaskByLastNameFilterVisible = settingsStore.hasFeature(FEATURES.TASK_BY_LAST_NAME_FILTER);
  const isEpisodesFilterVisible = settingsStore.hasFeature(FEATURES.EPISODES_FILTER);
  const isTaskOwnerFilterVisible = settingsStore.hasFeature(FEATURES.TASK_OWNER_FILTER);
  const isTaskRoleFilterVisible = settingsStore.hasFeature(FEATURES.TASK_ROLE_FILTER);
  const isTaskStatusFilterVisible = settingsStore.hasFeature(FEATURES.TASK_STATUS_FILTER);
  const hasAtLeastOneTaskFilter =
    isTaskSearchFilterVisible ||
    isTaskByLastNameFilterVisible ||
    isEpisodesFilterVisible ||
    isTaskOwnerFilterVisible ||
    isTaskRoleFilterVisible ||
    isTaskStatusFilterVisible;

  const onDropdownFilterChange = (
    values: ValueOf<SearchFiltersType>,
    filterKey: keyof SearchFiltersType,
    filterName: FilterName,
    actionMeta: ActionMeta<any>
  ) => {
    trackDropdownFilterUsageAnalyticsEvent(actionMeta, filterName, WorkQueueTabName.OpenItems);
    updateFiltersByKey(filterKey)(values);
  };

  const onTrackSearchAnalyticsEvent = useMemo(
    () =>
      debounce(
        (searchQuery: string) =>
          trackSearchByNameOrMrnFilterUsageAnalyticsEvent(searchQuery, WorkQueueTabName.OpenItems),
        DEBOUNCE_DELAY
      ),
    []
  );

  return (
    <>
      <StickyHeader mb={8}>
        <FilterSetDropdown
          activeFilterSetMetadata={activeFilterSetMetadata}
          filterSetsMetadata={filterSetsMetadata}
          isModified={isDirty}
          hasAnyActiveFilter={hasAnyActiveFilter}
          isLoading={isLoading}
          onFilterSetSelect={(filterSetId: number) => {
            chooseFilterSet(filterSetId);
          }}
          onSelectEdit={async (filterSetId: number) => {
            const filterSet = await loadFilterSetById(filterSetId);
            setFilterSetDialogConfig({
              ...defaultDialogConfig,
              open: true,
              mode: 'edit',
              id: filterSet.id,
              name: filterSet.name,
              isShared: filterSet.isShared,
              selectedFilters: filterSet.filters
            });
          }}
          onSelectCreate={() => {
            setFilterSetDialogConfig({
              ...defaultDialogConfig,
              open: true,
              mode: 'create',
              isShared: false,
              name: undefined,
              selectedFilters: filters
            });
          }}
          onSelectClear={() => {
            chooseFilterSet(null);
          }}
        />
      </StickyHeader>

      <FilterSetDialog {...filterSetDialogConfig} />

      {isTicketSearchFilterVisible && (
        <SearchBar
          variant="secondary"
          className="search"
          label="Name & MRN"
          searchValue={filters.searchTerm || ''}
          onSearchChanged={(value) => {
            onTrackSearchAnalyticsEvent(value);
            updateFiltersByKey('searchTerm')(value);
          }}
          testHook="search-by-name-or-mrn"
        />
      )}

      <TicketTypeFilterField
        onChange={(values, actionMeta) =>
          onDropdownFilterChange(values, 'ticketType', FilterName.ItemTypes, actionMeta)
        }
        value={filters.ticketType!}
        variant="secondary"
      />

      {isPatientTagsFilterVisible && (
        <TagsFilterField
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'patientTags', FilterName.PatientTags, actionMeta)
          }
          value={filters.patientTags!}
          variant="secondary"
        />
      )}

      {isProvidersFilterVisible && (
        <ProvidersFilterField
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'providers', FilterName.Providers, actionMeta)
          }
          value={filters.providers!}
          variant="secondary"
        />
      )}

      {isLocationsFilterVisible && (
        <LocationsFilterField
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'locations', FilterName.Locations, actionMeta)
          }
          value={filters.locations!}
          variant="secondary"
        />
      )}

      {isRegionsFilterVisible && (
        <RegionsFilterField
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'locations', FilterName.Locations, actionMeta)
          }
          value={filters.locations!}
          variant="secondary"
        />
      )}

      {isAssigneesFilterVisible && (
        <CliniciansFilterField
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'assignees', FilterName.Assignees, actionMeta)
          }
          value={filters.assignees!}
          variant="secondary"
        />
      )}

      {hasAtLeastOneTaskFilter && (
        <Text color="secondary" mt={12} display="inline-block">
          TASK FILTERS
        </Text>
      )}

      {isTaskSearchFilterVisible && (
        <SearchBar
          className="search"
          label="Title & Description"
          searchValue={filters.taskSearchTerm || ''}
          onSearchChanged={(value) => {
            onTrackSearchAnalyticsEvent(value);
            updateFiltersByKey('taskSearchTerm')(value);
          }}
          testHook="search-by-task"
          variant="secondary"
        />
      )}

      {isTaskByLastNameFilterVisible && (
        <LastNameFirstLetterFilterField
          value={filters.lastNameFirstLetter!}
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(
              values,
              'lastNameFirstLetter',
              FilterName.LastNameFirstLetter,
              actionMeta
            )
          }
          variant="secondary"
        />
      )}

      {isEpisodesFilterVisible && (
        <EpisodesFilterField
          value={filters.episodeIds!}
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'episodeIds', FilterName.Episodes, actionMeta)
          }
          variant="secondary"
        />
      )}

      {isTaskOwnerFilterVisible && (
        <EpisodeOwnersFilterField
          value={filters.owners!}
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'owners', FilterName.TaskOwner, actionMeta)
          }
          variant="secondary"
          label="Owner"
        />
      )}

      {isTaskRoleFilterVisible && (
        <TaskRolesFilterField
          value={filters.role!}
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'role', FilterName.TaskRole, actionMeta)
          }
          variant="secondary"
          label="Role"
        />
      )}

      {isTaskStatusFilterVisible && (
        <TaskStatusFilterField
          options={TICKET_ACTIVE_STATUS_OPTIONS}
          value={filters.status!}
          onChange={(values, actionMeta) =>
            onDropdownFilterChange(values, 'status', FilterName.TaskStatus, actionMeta)
          }
          variant="secondary"
          label="Status"
        />
      )}
    </>
  );
});

const StickyHeader = styled(Box)(
  ({ theme }) => css`
    position: sticky;
    top: -${theme.spacing(20)};
    z-index: 100;
    background: ${theme.palette.natural.contrast};
    background: linear-gradient(
      180deg,
      rgba(251, 251, 249, 1) 20%,
      rgba(251, 251, 249, 0.85) 50%,
      rgba(251, 251, 249, 0.75) 80%,
      rgba(251, 251, 249, 0) 100%
    );
    margin: -${theme.spacing(20)} -${theme.spacing(20)} 0;
    padding: ${theme.spacing(20, 20, 8, 20)};
  `
);
