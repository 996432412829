import { ChangeEvent, FC, MouseEvent } from 'react';

import { Box, css, styled } from '@mui/material';

import { PasteFromClipboardButton } from 'views/Pages/Invitation/PasteFromClipboardButton';

import { SearchCircle } from 'views/Widgets/icons/SearchCircle';

import StyledInput from 'views/Widgets/StyledInput';

import IconButton from 'components/Buttons/IconButton';
import Icon from 'components/Icons/Icon';

interface SearchBarProps {
  searchValue: string;
  onSearchChanged: (searchTerm: string) => void;
  onGoClicked: (isKeyboardSource?: boolean) => void;
  onPasteFromClipboard?: (searchTerm: string) => void;
  isLoading: boolean;
  toggleAdvancedSearch: () => void;
  clearSearch: (event: MouseEvent<HTMLButtonElement>) => void;
  placeholder: string;
  hasPasteMrnOption: boolean;
  testHooks?: {
    input?: string;
    searchButton?: string;
  };
}

export const BasicSearchBox: FC<SearchBarProps> = ({
  searchValue,
  onSearchChanged,
  onGoClicked,
  isLoading,
  toggleAdvancedSearch,
  clearSearch,
  placeholder,
  hasPasteMrnOption,
  onPasteFromClipboard,
  testHooks
}) => {
  const handlePasteFromClipboard = (clipboardText: string) => {
    if (clipboardText === searchValue || !clipboardText) {
      return;
    }

    onPasteFromClipboard && onPasteFromClipboard(clipboardText);
  };

  return (
    <StyledInput
      placeholder={placeholder}
      type="text"
      value={searchValue}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChanged(e.target.value)}
      testHook={testHooks?.input || 'global-patients-search-input'}
    >
      <div className="search-buttons p-2 mr-1" onClick={(event) => event.stopPropagation()}>
        <Box display="flex" alignItems="center">
          {searchValue.length > 0 ? (
            <>
              {hasPasteMrnOption && (
                <Box mr={20}>
                  <PasteFromClipboardButton onClick={handlePasteFromClipboard} />
                </Box>
              )}

              <IconButton onClick={clearSearch}>
                <Icon.XThin />
              </IconButton>
            </>
          ) : (
            <Box display="flex" alignItems="center">
              {hasPasteMrnOption && (
                <Box mr={20}>
                  <PasteFromClipboardButton onClick={handlePasteFromClipboard} />
                </Box>
              )}

              <StyledSettingsIcon
                onClick={(event) => {
                  event.stopPropagation();
                  toggleAdvancedSearch();
                }}
              />
            </Box>
          )}
        </Box>

        <StyledIconButton onClick={() => onGoClicked()} testHook={testHooks?.searchButton}>
          {isLoading && (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}

          <SearchCircle />
        </StyledIconButton>
      </div>
    </StyledInput>
  );
};

const StyledSettingsIcon = styled(Icon.Settings)(
  ({ theme }) => css`
    cursor: pointer;

    &:hover {
      color: ${theme.palette.primary.main};
    }
  `
);

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(8)};
  `
);
